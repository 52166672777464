import React from "react";
import { useTranslation } from 'react-i18next';
import {Chip, Typography} from "@mui/material";

import {FiberManualRecordIcon} from "../../../assets/Icons/Icons";

const StatusIndicator = ({ isOn }) => {
    const { t } = useTranslation('controller');
    return (
        <Chip
            icon={<FiberManualRecordIcon style={{ color: isOn ? '#4EB6B5' : '#B1494A' }} />}
            label={
                <Typography variant="body2" component="span">
                    {isOn ? t('on') : t('off')}
                </Typography>
            }
            variant="outlined"
            size="small"
        />
    );
};

export default StatusIndicator;
