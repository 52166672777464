import React from 'react';
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {Box, Typography} from "@mui/material";

import {Pinwheel} from "@uiball/loaders";

const LoadingBox = styled(Box)({
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #37589D",
    borderRadius: "15px",
    padding: ".75em",
});

function AppLoader() {
    const { t } = useTranslation('common');

    return (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
                <LoadingBox>
                    <Pinwheel size={55} lineWeight={2.5} speed={.85} color='#4EB6B5' />
                </LoadingBox>
                <Typography mt=".5em" color="#37589D" fontWeight="600">{t('loading')}</Typography>
            </Box>
    );
}

export default AppLoader;
