import React, { useState } from 'react';
import {Box, FormControl, NativeSelect} from '@mui/material';
import { useTranslation } from 'react-i18next';

function LangSelector() {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (event) => {
        const lang = event.target.value;
        setLanguage(lang);
        i18n.changeLanguage(lang);
    };

    return (
        <Box alignSelf="center" mb={1}>
            <FormControl >
                <NativeSelect
                    sx={{ fontSize: '14px' }}
                    disableUnderline
                    value={language}
                    onChange={handleLanguageChange}
                    inputProps={{
                        name: 'language',
                        id: 'language-selector',
                    }}
                >
                    <option value="en">English</option>
                    <option value="fr">Français</option>
                </NativeSelect>
            </FormControl>
        </Box>

    );
}

export default LangSelector;
