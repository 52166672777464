import React from "react";
import { useTranslation } from 'react-i18next';

import {List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";

import Divider from "@mui/material/Divider";
import {EmailIcon, EngineeringIcon, LocalPhoneIcon, OpenInNewIcon} from "../../assets/Icons/Icons";

function SideDrawer ({ navigate }) {
    const { t } = useTranslation('public');

    return (
        <List sx={{ padding: 1 }}>
            <Typography  color="secondaryText" variant="h6" align="center" m={1} >
                {t('getInTouch')}
            </Typography>
            <Divider />
            <ListItem disablePadding>
                <ListItemButton component="a" href="tel:423-735-7400">
                    <ListItemIcon>
                        <LocalPhoneIcon color="secondary" fontSize="small"  />
                    </ListItemIcon>
                    <ListItemText primary={t('callUs')} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component="a" href="mailto:info@stonemtntechnologies.com">
                    <ListItemIcon>
                        <EmailIcon color="secondary" fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText primary={t('emailUs')} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={() => navigate('/contractor_form')}>
                    <ListItemIcon>
                        <EngineeringIcon color="secondary" fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText primary={t('contractorRegistration')} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={() => window.open("https://stonemountaintechnologies.com", '_blank', 'noopener,noreferrer')}>
                    <ListItemIcon>
                        <OpenInNewIcon color="secondary" fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText primary={t('learnAboutUs')}/>
                </ListItemButton>
            </ListItem>
        </List>
    );
}

export default SideDrawer;
