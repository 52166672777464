import React, { useRef  } from 'react';
import { Box } from '@mui/material';

const ModelViewer = ({ src, large = false }) => {
    const modelViewerRef = useRef(null);

    return (
        <Box
            style={{ position: 'relative', width: '100%', height: large ? '30em' : '10em' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <model-viewer
                ref={modelViewerRef}
                src={src}
                exposure="0.5"
                shadow-intensity="0.5"
                shadow-softness="1"
                alt="A 3D model of an outdoor Anesi  GHP unit"
                auto-rotate
                camera-controls
                auto-rotate-center="0.5 0.5 0.5"
                orientation="0 90deg 0"
                style={{
                    width: '100%',
                    zIndex: 0,
                    height: '100%',
                }}
            >
            </model-viewer>
        </Box>
    );
};

export default ModelViewer;

