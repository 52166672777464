import React from 'react';
import { useTranslation } from 'react-i18next';
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import styled from '@emotion/styled';

import {useUser} from "../../contexts/UserProvider";
import {useUnits} from "../../contexts/UnitProvider";
import { BottomNavigation, BottomNavigationAction, Badge } from '@mui/material';
import { DescriptionIcon, NotificationImportantIcon, AssessmentIcon, HvacIcon } from '../../assets/Icons/Icons.js';

const StyledBottomNavigation = styled(BottomNavigation)({
    background: 'linear-gradient(#FCFCFF, #FCFCFF) padding-box, linear-gradient(to right, #37589D, #4EB6B5) border-box',
    borderTop: '2.5px solid transparent',
    height: 'auto',
    padding: '0.5rem',

    '& .MuiBottomNavigationAction-root.Mui-selected': {
        color: '#4EB6B5',
    }
});

const BottomNav = () => {
    const { t } = useTranslation('unitView');
    const { isContractor } = useUser();
    const { unitFaults } = useUnits();

    const navigate = useNavigate();
    const location = useLocation();
    const { unitId } = useParams();
    const [searchParams] = useSearchParams();

    let currentTab = 1;
    if (location.pathname.includes('more-data')) currentTab = 0;
    if (location.pathname.includes('documents')) currentTab = 2;
    if (location.pathname.includes('alerts')) currentTab = 3;

    const activeFaults = unitFaults
        ? unitFaults.filter(fault => fault.resolved === null)
        : [];


    const handleNavChange = (event, newValue) => {
        const sourceParam = searchParams.get('source') || 'units';
        const groupId = searchParams.get('groupId');
        const basePath = `/dashboard/my_units/${unitId}`;
        let url = '';

        switch (newValue) {
            case 0:
                url = `${basePath}/more-data?source=${sourceParam}`;
                break;
            case 1:
                url = `${basePath}?source=${sourceParam}`;
                break;
            case 2:
                url = `${basePath}/documents?source=${sourceParam}`;
                break;
            case 3:
                url = `${basePath}/alerts?source=${sourceParam}`;
                break;
            default:
                url = `${basePath}?source=${sourceParam}`;
        }

        if (sourceParam === 'groups' && groupId) {
            url += `&groupId=${groupId}`;
        }
        navigate(url);
    };

    return (
        <StyledBottomNavigation showLabels value={currentTab} onChange={handleNavChange}>
            {isContractor && (
                <BottomNavigationAction label={t('controller')} icon={<AssessmentIcon fontSize="large"/>} />
            )}
            <BottomNavigationAction label={t('unit')} icon={<HvacIcon fontSize="large"/>} />
            <BottomNavigationAction label={t('documents')} icon={<DescriptionIcon fontSize="large"/>} />
            <BottomNavigationAction label={t('alerts')}
                icon={
                    <Badge badgeContent={activeFaults.length} overlap="circular" color="error"  >
                        <NotificationImportantIcon fontSize="large"/>
                    </Badge>
                }
            />
        </StyledBottomNavigation>
    );
};

export default BottomNav;
