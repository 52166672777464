import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from "@emotion/styled";

import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Box, Typography, IconButton, InputAdornment, CircularProgress, useMediaQuery, Stack, Card } from "@mui/material";

import {Visibility, VisibilityOff} from '../../assets/Icons/Icons';
import AppContainer from "../../components/shared/AppContainer";
import AppHeader from "../../components/shared/AppHeader";
import AppTextInput from "../../components/shared/AppTextInput";
import AppButton from "../../components/shared/AppButton";
import { useUser } from "../../contexts/UserProvider";
import { useAlert } from "../../contexts/AlertsProvider";

import Logo from '../../assets/Images/anesi_Logo.png'

const LogoContainer = styled(Box)(({ theme, isdesktop }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1em',
    marginBottom: '1.5em',
    '& img': {
        width: isdesktop ? '5em' : '25%'
    }
}));

const ResponsiveContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

const DesktopContainer = styled(Card)(({ theme }) => ({
    width: '40%',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
    padding: '30px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px solid rgba(145, 158, 171, 0.1)',
}));

const logInSchema = (t) => Yup.object().shape({
    email: Yup.string().email(t('invalidEmail')).required(t('emailRequired')),
    password: Yup.string().required(t('passwordRequired')),
});

// TODO: Redo this logic, move forms and such into separate files/context. All over the place..
const LoginForm = ({ loading, handleSubmit, showPassword, handleShowPassword, rememberMe, handleRememberMeChange, savedEmail, navigate }) => {
    const { t } = useTranslation('public');

    return (
        <Formik initialValues={{ email: savedEmail, password: '' }} onSubmit={handleSubmit} validationSchema={logInSchema(t)}>
            <Form>
                <Stack spacing={1} my={1}>
                    <AppTextInput label={t('emailLabel')} name="email" fullWidth />
                    <AppTextInput
                        type={showPassword ? "text" : "password"}
                        label={t('password')}
                        name="password"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword}>
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <FormControlLabel control={ <Checkbox  sx={{ '&.Mui-checked': { color: 'success.main' } }} checked={rememberMe} onChange={handleRememberMeChange} name="rememberMe"/> }
                        label={ <Typography variant="body2" color="secondaryText">{t('rememberMe')}</Typography>} />
                </Stack>
                <Stack>
                    <div style={{ position: 'relative' }}>
                        <AppButton variant="primary" type="submit" disabled={loading}>
                            {t('signIn')}
                        </AppButton>
                        {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
                    </div>
                    <Typography textAlign="center" m={0.5}>
                        {t('or')}
                    </Typography>
                    <AppButton variant="secondary" onClick={() => navigate('/register')}>
                        {t('register')}
                    </AppButton>
                </Stack>
            </Form>
        </Formik>
    );
};

function LogInScreen() {
    const { t } = useTranslation('public');

    const isDesktop = useMediaQuery('(min-width:1024px)');
    const navigate = useNavigate();
    const location = useLocation();
    const { login } = useUser();
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const savedEmail = localStorage.getItem("rememberMeEmail") || '';
    const [rememberMe, setRememberMe] = useState(Boolean(savedEmail));
    const [showPassword, setShowPassword] = useState(false);
    const email = savedEmail;

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleRememberMeChange = (event) => {
        setRememberMe(event.target.checked);
        if (event.target.checked) {
            localStorage.setItem("rememberMeEmail", email);
        } else {
            localStorage.removeItem("rememberMeEmail");
        }
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        const { email, password } = values;
        if (rememberMe) {
            localStorage.setItem("rememberMeEmail", email);
        } else {
            localStorage.removeItem("rememberMeEmail");
        }
        const result = await login(email, password);
        if (result === 'fail') {
            alert(t('incorrectEmailOrPassword'), 'error');
        } else if (result === 'unapproved') {
            alert(t('accountNotApproved'), 'error');
        } else if (result === 'ok') {
            let next = '/';
            if (location.state && location.state.next) {
                next = location.state.next;
            }
            navigate(next);
        }
        setLoading(false);
    };

    return (
        <AppContainer>
            {!isDesktop && (<AppHeader backButtonRoute="/" />)}
            <LogoContainer isdesktop={isDesktop ? 1 : 0}>
                <img src={Logo} alt="Anesi Logo" />
            </LogoContainer>
            <ResponsiveContainer>
                {isDesktop ? (
                    <DesktopContainer>
                        <Box width="85%">
                            <>
                                <Typography fontWeight="700" variant="h6" >{t('welcomeBack')}</Typography>
                                <Typography variant="subtitle1" color="secondaryText">{t('pleaseEnterCredentials')}</Typography>
                            </>
                            <LoginForm
                                loading={loading}
                                handleSubmit={handleSubmit}
                                showPassword={showPassword}
                                handleShowPassword={handleShowPassword}
                                rememberMe={rememberMe}
                                handleRememberMeChange={handleRememberMeChange}
                                savedEmail={savedEmail}
                                navigate={navigate}
                            />
                        </Box>
                    </DesktopContainer>
                ) : (
                    <Stack width="100%">
                        <Typography variant="h6" >{t('welcomeBack')}</Typography>
                        <Typography variant="subtitle1" color="secondaryText">{t('pleaseEnterCredentials')}</Typography>
                        <LoginForm
                            loading={loading}
                            handleSubmit={handleSubmit}
                            showPassword={showPassword}
                            handleShowPassword={handleShowPassword}
                            rememberMe={rememberMe}
                            handleRememberMeChange={handleRememberMeChange}
                            savedEmail={savedEmail}
                            navigate={navigate}
                        />
                    </Stack>
                )}
            </ResponsiveContainer>
            <Typography
                onClick={() => navigate('/password_reset')}
                textAlign="center"
                mt={2}
                style={{ cursor: 'pointer' }}
            >
                {t('forgotPassword')}
            </Typography>
        </AppContainer>
    );
}

export default LogInScreen;
