import React from 'react';
import { Drawer, IconButton, Stack, Typography} from "@mui/material";
import styled from "@emotion/styled";

import {CloseIcon} from '../../assets/Icons/Icons.js';

const StyledDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
        background: 'linear-gradient(#FCFCFF, #FCFCFF) padding-box, linear-gradient(to right, #37589D, #4EB6B5) border-box',
        borderTop: '7px solid transparent',
        borderRadius: '25px 25px 0 0',
        height: '90%',
        backgroundColor: '#FCFCFF',
    },
});

function AppDrawer({ children, open, onClose, title }) {
    return (
        <StyledDrawer anchor="bottom" open={open} onClose={onClose}>
            <Stack p={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1" fontWeight="bold" color="secondaryText.main">
                        {title}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon color="secondary"  />
                    </IconButton>
                </Stack>
                {children}
            </Stack>
        </StyledDrawer>
    );
}

export default AppDrawer;


