import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {CircularProgress, Stack, Typography} from "@mui/material";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import AppContainer from "../../components/shared/AppContainer";
import AppTextInput from "../../components/shared/AppTextInput";
import AppButton from "../../components/shared/AppButton";
import AppHeader from "../../components/shared/AppHeader";
import {useAlert} from "../../contexts/AlertsProvider";
import {useApi} from "../../contexts/ApiProvider";

const PasswordResetSchema = (t) => Yup.object().shape({
    password: Yup.string()
        .matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            t('passwordRequirements')
        )
        .required(t('required')),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], t('passwordsMustMatch'))
        .required(t('required')),
});

function PasswordResettingScreen() {
    const { t } = useTranslation('public');

    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const api = useApi();
    const navigate = useNavigate();
    const { search } = useLocation();
    const token = new URLSearchParams(search).get('token');

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
    }, []);

    const handleSubmit = async (values) => {
        setLoading(true);
        const submitData = {
            reset_token: token,
            new_password: values.confirmPassword,
        };
        const response = await api.put('/tokens/reset', submitData);
        if (response.ok) {
            alert(t('passwordResetSuccess'), 'success');
            navigate('/login');
        } else {
            alert(t('passwordResetFailure'), 'error');
            navigate('/password_reset');
        }
        setLoading(false);
    }

    return (
        <AppContainer>
            <AppHeader backButtonRoute="/" />
            <Typography variant="h6" color="secondaryText" my={1.5}>
                {t('pleaseFillFields')}
            </Typography>
            <Formik initialValues={{ password: '', confirmPassword: ''}} onSubmit={handleSubmit} validationSchema={PasswordResetSchema(t)}>
                <Form>
                    <Stack spacing={2}>
                        <AppTextInput  name="password" label={t('password')} type="password" placeholder={t('password')}/>
                        <AppTextInput  name="confirmPassword" label={t('passwordAgain')}  type="password" placeholder={t('confirmPassword')}/>
                        <div style={{ position: 'relative' }}>
                            <AppButton disabled={loading} variant="primary" type="submit" style={{ marginTop: '1em' }}>{t('resetPassword')}</AppButton>
                            {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '60%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
                        </div>
                    </Stack>
                </Form>
            </Formik>
        </AppContainer>
    );
}

export default PasswordResettingScreen;
