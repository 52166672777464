import React from 'react';
import {Skeleton, Stack} from "@mui/material";

import StateStatus from "./StateStatus";
import TempStatus from "./TempStatus";

function UnitStatus({ unitData, isMetric, contractor, unitType }) {
    const fontSize = '0.9em'

    // if (!unitData) {
    //     return (
    //         <Stack spacing={1}>
    //             <Skeleton variant="rectangular" height={50} />
    //             <Skeleton variant="rectangular" height={50} />
    //         </Stack>
    //     );
    // }

    return (
        <Stack spacing={1}>
            <StateStatus unitData={unitData} unitType={unitType} fontSize={fontSize} />
            <TempStatus
                unitData={unitData}
                isMetric={isMetric}
                contractor={contractor}
                unitType={unitType}
                fontSize={fontSize}
            />
        </Stack>
    );
}

export default UnitStatus;
