import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from "@emotion/styled";

import {
    Box,
    Typography,
    Tooltip,
    Checkbox,
    TextField,
    Divider,
    FormControlLabel
} from "@mui/material";

import {SearchIcon} from '../../assets/Icons/Icons.js';
import AppCard from "../shared/AppCard";
import AppDivider from "../shared/AppDivider";
import AppButton from "../shared/AppButton";
import AppSearchBar from "../shared/AppSearchBar";

// custom component
const StyledInput = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#37589D',
            borderWidth: 1,
            borderRadius: 12,
        },
        '&.Mui-focused fieldset': {
            borderColor: '#37589D',
        },
        '& .MuiInputBase-input': {
            height: '2.5em',
            padding: '0 1em',
        },
    },
});

export function UnitInfoContent({ selectedUnit, handleToolTip, tooltipOpen, fullAddress, installAddress, loggedInUser }) {
    const { t } = useTranslation('units');
    console.log(selectedUnit)

    const getUserLabel = (user) => {
        if (user.id === loggedInUser.id) {
            return 'Me';
        } else if (user.role === 0) {
            return 'Owner';
        } else {
            return 'Contractor';
        }
    };

    return (
        <>
            <Typography fontWeight="bold" color="#959292" mb={0.5}>
                {t('unitQuickInfo.ownerInformation')}
            </Typography>
            <AppCard>
                <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight="600" color="#959292">{t('unitQuickInfo.name')}</Typography>
                    <Typography fontWeight="bold">
                        {selectedUnit && selectedUnit.users && selectedUnit.users.length > 0 ? (
                            (() => {
                                const owner = selectedUnit.users.find(user => user.role === 0);
                                return owner ? `${owner.first_name} ${owner.last_name}` : t('unitQuickInfo.unavailable');
                            })()
                        ) : t('unitQuickInfo.unavailable')}
                    </Typography>
                </Box>
                <AppDivider inCard="true" />
                <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight="600" color="#959292">{t('unitQuickInfo.primaryAddress')}</Typography>
                    <Tooltip
                        title={fullAddress}
                        placement="bottom"
                        onClick={handleToolTip}
                        open={tooltipOpen}
                        disableHoverListener
                        arrow
                    >
                        <Typography color="#37589D" style={{ cursor: 'pointer' }} fontWeight="bold">{t('unitQuickInfo.seeAddress')}</Typography>
                    </Tooltip>
                </Box>
            </AppCard>
            <Typography fontWeight="bold" color="#959292" mb={0.5} mt={2}>
                {t('unitQuickInfo.unitBasics')}
            </Typography>
            <AppCard>
                <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight="600" color="#959292">{t('unitQuickInfo.serialNumber')}</Typography>
                    <Typography fontWeight="bold">{selectedUnit && selectedUnit.serial_number}</Typography>
                </Box>
                <AppDivider inCard="true" />
                <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight="600" color="#959292">{t('unitQuickInfo.installationDate')}</Typography>
                    <Typography fontWeight="bold">  {selectedUnit && selectedUnit.install_date
                        ? new Date(selectedUnit.install_date * 1000).toLocaleDateString()
                        : t('unitQuickInfo.unavailable')}
                    </Typography>
                </Box>
                <AppDivider inCard="true" />
                <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight="600" color="#959292">{t('unitQuickInfo.lastAccessedBy')}</Typography>
                    <Typography fontWeight="bold">
                        {selectedUnit && selectedUnit.last_maintained_by
                            ? `${selectedUnit.last_maintained_by.first_name.charAt(0)}. ${selectedUnit.last_maintained_by.last_name}`
                            : t('unitQuickInfo.unavailable')}
                    </Typography>
                </Box>
                <AppDivider inCard="true" />
                <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight="600" color="#959292">{t('unitQuickInfo.location')}</Typography>
                    <Tooltip
                        title={installAddress}
                        placement="bottom"
                        onClick={handleToolTip}
                        open={tooltipOpen}
                        disableHoverListener
                        arrow
                    >
                        <Typography color="#37589D" style={{ cursor: 'pointer' }} fontWeight="bold">{t('unitQuickInfo.seeAddress')}</Typography>
                    </Tooltip>
                </Box>
                <AppDivider inCard="true" />
                <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight="600" color="#959292">{t('unitQuickInfo.firmwareVersion')}</Typography>
                    <Typography fontWeight="bold">
                        {selectedUnit && selectedUnit.firmware
                            ? `${selectedUnit.firmware.fw_major}.${selectedUnit.firmware.fw_minor}`
                            : t('unitQuickInfo.unavailable')}
                    </Typography>
                </Box>
                <AppDivider inCard="true" />
                <Box display="flex" justifyContent="space-between" flexDirection="column">
                    <Typography fontWeight="600" color="#959292">{t('unitQuickInfo.dataAccessibleTo')}</Typography>
                    {selectedUnit && selectedUnit.users && selectedUnit.users.length > 0 ? (
                        selectedUnit.users.map((user, index) => (
                            <Typography key={index} fontWeight="bold" fontSize="0.9em">
                                {user.first_name.charAt(0)}. {user.last_name} - {getUserLabel(user)}
                            </Typography>
                        ))
                    ) : (
                        <Typography fontWeight="600">{t('unitQuickInfo.noUsersAvailable')}</Typography>
                    )}
                </Box>
            </AppCard>
        </>
    );
}

export function CreateGroupContent({ groupName, setGroupName, searchGroupTerm, setSearchGroupTerm, filteredGroupUnits, selectedGroupUnits, toggleUnitSelection, handleSaveGroup}) {
    const { t } = useTranslation('groups');

    return (
        <>
            <Box mt={1} position="relative">
                <Typography fontWeight="bold" color="#959292" mb={1}>
                    {t('createGroup.nameYourGroup')}
                </Typography>
                {/*use component..*/}
                <StyledInput
                    fullWidth
                    variant="outlined"
                    placeholder={t('createGroup.groupNamePlaceholder')}
                    value={groupName}
                    onChange={e => setGroupName(e.target.value)}
                    InputProps={{ style: { borderRadius: 10 } }}
                />
            </Box>
            <Box>
                <AppSearchBar placeholder={t('createGroup.searchUnitsPlaceholder')} value={searchGroupTerm} onChange={e => setSearchGroupTerm(e.target.value)} endAdornment={<SearchIcon />} />
                <Typography fontWeight="bold" color="#959292" my={1}>
                    {t('createGroup.selectUnitsToAdd')}
                </Typography>
                {filteredGroupUnits && filteredGroupUnits.length > 0 ? (
                    <Box mt={1}>
                        {filteredGroupUnits.map(unit => (
                            <Box key={unit.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedGroupUnits.includes(unit.id)}
                                            onChange={() => toggleUnitSelection(unit.id)}
                                            size="small"
                                            color="success"
                                        />
                                    }
                                    label={`SN: ${unit.serial_number}`}
                                    sx={{
                                        '.MuiFormControlLabel-label': {
                                            fontSize: '0.9em',
                                        },
                                    }}
                                />
                                <Divider />
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Typography mt={1} textAlign="center" variant="h6">
                        {t('createGroup.noUnitsMatchSearch')}
                    </Typography>
                )}
            </Box>
            <Typography variant="body2" textAlign="center" fontWeight="bold" color="#959292" mt={1} >
                {t('createGroup.unitMayBeGroupedMultipleTimes')}
            </Typography>
            <Box mt={3} display="flex" justifyContent="center">
                <AppButton color="primary" onClick={handleSaveGroup} disabled={groupName === '' || selectedGroupUnits.length === 0}>
                    {t('createGroup.saveGroup')}
                </AppButton>
            </Box>
        </>
    );
}

