import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Box, Card, Checkbox, Stack, Stepper, TextField, Typography } from "@mui/material";

import AppContainer from "../../components/shared/AppContainer";
import AppHeader from "../../components/shared/AppHeader";
import AppButton from "../../components/shared/AppButton";
import AppCard from "../../components/shared/AppCard";
import AppAccordion from "../../components/shared/AppAccordion";
import { useAlert } from "../../contexts/AlertsProvider";
import { useUser } from "../../contexts/UserProvider";
import { useApi } from "../../contexts/ApiProvider";
import AppSwitch from "../../components/shared/AppSwitch";

const outdoorUnitSteps = [
    {
        label: 'Heat pump placed on a flat and level (within 2) surface outside.',
        subtasks: []
    },
    {
        label: 'Heat pump clearances meet specs.',
        subtasks: [
            '24" (61 cm) clearance - Front Panel',
            '24” (61 cm) clearance – Flue Vent Side',
            '18” (46 cm) clearance – Opposite Side (electrical knockouts)',
            '18” (46 cm) clearance – Back Side',
        ],
    },
    {
        label: 'Natural gas line connected and has been leak checked per local requirements.',
        subtasks: ['Manual shutoff valve is installed before the drip leg outside of the heat pump.']
    },
    {
        label: 'The gas line has been purged of air.',
        subtasks: []
    },
    {
        label: 'Visual inspection of heat pump to ensure nothing was dislodged, disconnected, or damaged during shipping and installation.',
        subtasks: []
    },
    {
        label: 'Confirm Flue Vent is properly installed and meets clearance requirements.',
        subtasks: []
    },
    {
        label: 'Confirm Condensate management was handled correctly and per local code requirements.',
        details: [
            'Option 1: Condensate line ran indoors to a drain or pump, line heat traced & insulated, a trap made somewhere along the line, and proper slope.',
            'Option 2: A hole dug with an insulated line dropping in & backfilled w/ Limestone past the frost line.',
        ],
        subtasks: []
    },
    {
        label: 'Ensure all hydronic line isolation valves are open (as long as hydronic system install is complete).',
        subtasks: []
    },
    {
        label: 'All outdoor hydronic lines have been insulated with a minimum R-8 insulation.',
        subtasks: []
    },
    {
        label: 'Confirm 115 VAC is run to the GAHP through an electrical disconnect near the unit.',
        subtasks: []
    },
    {
        label: 'Confirm Low Voltage wires are routed separately from the High Voltage wires.',
        subtasks: []
    },
    {
        label: 'Confirm 115 VAC terminations in the GAHP control box are correct, as-in Hot is ran to the terminal labelled “L”, Neutral ran to “N”, and Ground to “G”.',
        subtasks: []
    },
    {
        label: 'Control wiring has been correctly installed in the GAHP control box. Perform any that apply:',
        details: [
            'Modbus Communication',
            'Thermostat Signals: W1, W2, WH, R',
            'GAHP Error Signal – when connected to custom ANESI AHU',
            'Hydronic Pump Relay (24VAC) – when applicable',
            'Indirect Storage Tank Temperature (Thermistor) – when applicable for commercial water heating applications without an Aquastat',
        ],
        subtasks: []
    },
    {
        label: 'Confirm external antenna has been properly installed.',
        subtasks: []
    }
];

const turnOnOutdoorSteps = [
    {
        label: 'Confirm there are no active faults.',
        subtasks: []
    }
];

const combiApplicationsSteps = [
    {
        label: 'Confirm indirect storage tank is filled with water, both hot and cold domestic water valves are open, and an expansion tank is connected in the potable water piping feeding the tank.',
        subtasks: []
    },
    {
        label: 'Disconnect control wire from indirect storage water heater Aquastat.',
        subtasks: []
    },
    {
        label: 'Set the space heating thermostat such that a space heating call (Stage 1) is sent out.',
        subtasks: []
    },
    {
        label: 'Allow heat pump to turn on and confirm burner lights.',
        subtasks: []
    },
    {
        label: 'While the GAHP is running confirm there are no gas leaks inside the GAHP, in case something came loose or was damaged during shipment.',
        subtasks: []
    },
    {
        label: 'Measure the combustion emissions at the flue gas vent at close to full fire and minimum fire.',
        subtasks: []
    },
    {
        label: 'Set the combustion back to full fire and clock the gas consumption.',
        subtasks: []
    },
    {
        label: 'Re-connect water heater thermostat wiring.',
        subtasks: []
    },
    {
        label: 'Feel the hydronic lines feeding the storage tank. Confirm they are getting hot signifying that the tank is being heated.',
        subtasks: []
    },
    {
        label: 'Clean up the work area, reinstall all panels, and walk through how the system operates with the homeowner.',
        subtasks: []
    }
];

const TaskStep = ({ step, index, checked, handleCheckboxChange, section }) => {
    return (
        <Card
            elevation={0}
            sx={{
                border: 'dashed',
                borderWidth: '1px',
                color: 'secondary.main',
                marginY: '2.5px'
            }}
        >
            <Stack direction="row" alignItems="center" >
                <Checkbox
                    sx={{
                        '& .MuiSvgIcon-root': { fontSize: '1.5em' },
                        color: '#37589D',
                        '&.Mui-checked': { color: 'success.main' }
                    }}
                    checked={checked[`${section}.${index}`] || false}
                    onChange={() => handleCheckboxChange(section, index)}
                />
                <Typography fontWeight="bold" variant="body2">
                    {step.label}
                </Typography>
            </Stack>

            {/* Subtasks or details */}
            <Box pl={1}>
                {step.details && step.details.length > 0 ? (
                    step.details.map((detail, i) => (
                        <ul key={i}>
                            <Typography variant="body2">
                                <li>{detail}</li>
                            </Typography>
                        </ul>
                    ))
                ) : (
                    step.subtasks.map((subtask, i) => (
                        <Stack key={i} direction="row" alignItems="center">
                            <Checkbox
                                sx={{
                                    '& .MuiSvgIcon-root': { fontSize: '1em' },
                                    color: '#37589D',
                                    '&.Mui-checked': { color: '#37589D' }
                                }}
                                checked={checked[`${section}.${index}.${i}`] || false}
                                onChange={() => handleCheckboxChange(`${section}.${index}`, i)}
                            />
                            <Typography variant="body2">
                                {subtask}
                            </Typography>
                        </Stack>
                    ))
                )}
            </Box>
        </Card>
    );
};

function AssistantScreen() {
    const alert = useAlert();
    const api = useApi();
    const navigate = useNavigate();
    const { user, isContractor } = useUser();
    const { unitId } = useParams();

    const [checked, setChecked] = useState({});
    const [showSignatureCard, setShowSignatureCard] = useState(false);
    const currentDate = new Date().toISOString().split("T")[0];
    const [totalCheckboxes, setTotalCheckboxes] = useState(0);
    const [checkedCount, setCheckedCount] = useState(0);
    const [isOnlySpaceHeating, setIsOnlySpaceHeating] = useState(false);

    const handleSign = () => {
        setShowSignatureCard(!showSignatureCard);
    };

    const handleCheckboxChange = (section, index, subIndex = null) => {
        const key = subIndex !== null
            ? `${section}.${index}.${subIndex}`
            : `${section}.${index}`;
        setChecked(prevChecked => ({
            ...prevChecked,
            [key]: !prevChecked[key]
        }));
    };

    function getVisibleTasks() {
        const outdoor = outdoorUnitSteps;
        const turnOn = turnOnOutdoorSteps;

        let combi = combiApplicationsSteps;
        if (isOnlySpaceHeating) {
            combi = combi.filter((_, idx) => ![0,1,7,8].includes(idx));
        }

        return [outdoor, turnOn, combi];
    }

    const handleSignature = async () => {
        const response = await api.post('/signature', {
            document_type: 'Installation',
            unit_id: unitId,
        });
        if (response.ok) {
            alert('Successfully provided signature', 'success');
            navigate('/dashboard/my_units');
        } else {
            alert('Could not save signature. Please try again', 'error');
        }
    };

    useEffect(() => {
        const [outdoor, turnOn, combi] = getVisibleTasks();

        let total = 0;
        let visibleChecked = 0;
        const sections = ['outdoorUnit', 'turnOnOutdoor', 'combiApplications'];
        const allSteps = [outdoor, turnOn, combi];

        allSteps.forEach((steps, sectionIndex) => {
            const sectionName = sections[sectionIndex];
            steps.forEach((step, stepIndex) => {
                total++;
                if (checked[`${sectionName}.${stepIndex}`]) {
                    visibleChecked++;
                }

                // Subtasks
                step.subtasks?.forEach((_, subIndex) => {
                    total++;
                    if (checked[`${sectionName}.${stepIndex}.${subIndex}`]) {
                        visibleChecked++;
                    }
                });
            });
        });

        setTotalCheckboxes(total);
        setCheckedCount(visibleChecked);
    }, [checked, isOnlySpaceHeating]);

    useEffect(() => {
        if (isContractor === false) {
            navigate('/dashboard');
        }
    }, [isContractor, navigate]);

    const allChecked = (checkedCount === totalCheckboxes);
    const [outdoor, turnOn, combi] = getVisibleTasks();

    return (
        <AppContainer>
            <AppHeader title="Assistant" />
            <Typography textAlign="center" fontWeight="bold" my="1em">
                Follow the checklists for installation.
            </Typography>
            <Stack spacing={0.25}>
                {/* Outdoor Unit Steps */}
                <AppAccordion title="Outdoor Unit GHP">
                    <Stepper orientation="vertical">
                        {outdoor.map((step, index) => (
                            <TaskStep
                                key={index}
                                step={step}
                                index={index}
                                section="outdoorUnit"
                                checked={checked}
                                handleCheckboxChange={handleCheckboxChange}
                            />
                        ))}
                    </Stepper>
                </AppAccordion>

                {/* Turn on Outdoor Unit Steps */}
                <AppAccordion title="Turn on Outdoor Unit GHP">
                    <Stepper orientation="vertical">
                        {turnOn.map((step, index) => (
                            <TaskStep
                                key={index}
                                step={step}
                                index={index}
                                section="turnOnOutdoor"
                                checked={checked}
                                handleCheckboxChange={handleCheckboxChange}
                            />
                        ))}
                    </Stepper>
                </AppAccordion>

                {/* Combi Applications */}
                <AppAccordion title="COMBI Applications">
                    <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                        <AppSwitch
                            checked={isOnlySpaceHeating}
                            onChange={(e) => setIsOnlySpaceHeating(e.target.checked)}
                        />
                        <Typography variant="body2">
                            Space Heating Only
                        </Typography>
                    </Stack>
                    <Stepper orientation="vertical">
                        {combi.map((step, index) => (
                            <TaskStep
                                key={index}
                                step={step}
                                index={index}
                                section="combiApplications"
                                checked={checked}
                                handleCheckboxChange={handleCheckboxChange}
                            />
                        ))}
                    </Stepper>
                </AppAccordion>
            </Stack>

            <AppButton style={{ marginTop: '1em' }} onClick={handleSign} disabled={!allChecked}>
                Complete
            </AppButton>

            {/* Signature Card */}
            <Box
                mt={2}
                style={{
                    maxHeight: showSignatureCard ? '350px' : '0',
                    overflow: 'hidden',
                    transition: 'max-height 0.5s ease-in-out'
                }}
            >
                <AppCard type="good">
                    <Typography color="#959292" variant="body2" fontWeight="bold" textAlign="center">
                        Provide E-signature to acknowledge completion of all tasks.
                    </Typography>
                    <Stack my={1} alignItems="center">
                        <TextField
                            variant="standard"
                            style={{ width: '85%' }}
                            InputProps={{
                                style: { borderBottom: '1px solid #F8F8F8' },
                                value: `${user.first_name} ${user.last_name}`
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { display: 'none' },
                            }}
                        />
                        <Typography color="#37589D" variant="body2" >
                            E-signature
                        </Typography>
                    </Stack>
                    <Stack alignItems="center" mb={1}>
                        <Typography color="#37589D" fontWeight="bold">
                            {currentDate}
                        </Typography>
                        <Typography fontSize="0.85em" color="#37589D" style={{ fontWeight: '500' }}>
                            Date
                        </Typography>
                    </Stack>
                    <AppButton onClick={handleSignature}>
                        Sign
                    </AppButton>
                </AppCard>
            </Box>
        </AppContainer>
    );
}

export default AssistantScreen;
