import React, { useState } from 'react';
import {useTranslation} from "react-i18next";

import {
    TextField,
    Typography,
    MenuItem, FormControl, Select, InputLabel, Stack
} from '@mui/material';

import AppContainer from '../shared/AppContainer';
import AppHeader from '../shared/AppHeader';
import AppButton from '../shared/AppButton';
import {formatPostalCode, formatState, formatZipCode} from "../../utilities/validationAndInputs";

function AddUnitThree({ onSubmit, onLocationChange }) {
    const { t } = useTranslation('units');
    const [country, setCountry] = useState('');

    const [address, setAddress] = useState({
        address_line_1: '',
        address_line_2: '',
        city: '',
        state_abbreviation: '',
        zipcode: '',
    });

    const handleCountryChange = (event) => {
        const newCountry = event.target.value;
        setCountry(newCountry);

        setAddress((prev) => ({
            ...prev,
            state_abbreviation: '',
            zipcode: '',
        }));
    };

    const handleInputChange = (field, value) => {
        let newValue = value;

        if (field === 'state_abbreviation') {
            newValue = formatState(newValue);
        }
        else if (field === 'zipcode') {
            if (country === 'US') {
                newValue = formatZipCode(newValue);
            } else if (country === 'CA') {
                newValue = formatPostalCode(newValue);
            }
        }

        setAddress((prevAddress) => ({
            ...prevAddress,
            [field]: newValue,
        }));

        onLocationChange((prevLocation) => ({
            ...prevLocation,
            [field]: newValue,
        }));
    };

    const canSubmit = () => {
        if (!country) return false;
        if (!address.address_line_1 || !address.city) return false;

        return !(address.state_abbreviation.trim() === '' || address.zipcode.trim() === '');

    };

    return (
        <AppContainer style={{ width: '100%' }}>
            <AppHeader
                title={t('addUnit.Three.unitsLocation')}
                backButtonRoute="/dashboard/my_units"
            />

            <Typography fontWeight="600"  mt={1.5} mb={1}>
                Please select country
            </Typography>

            <FormControl size="small" fullWidth>
                <InputLabel id="country-label">Country</InputLabel>
                <Select
                    labelId="country-label"
                    id="country"
                    value={country}
                    onChange={handleCountryChange}
                    label="Country"
                >
                    <MenuItem value="US">United States of America</MenuItem>
                    <MenuItem value="CA">Canada</MenuItem>
                </Select>
            </FormControl>

            {country && (
                <Stack mt={2} padding={0.5}>
                    <Stack>
                        <TextField
                            label="Address Line 1"
                            variant="standard"
                            fullWidth
                            placeholder="e.g. 123 Main Street"
                            value={address.address_line_1}
                            onChange={(e) =>
                                handleInputChange('address_line_1', e.target.value)
                            }
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                        <TextField
                            label="Address Line 2 (optional)"
                            variant="standard"
                            fullWidth
                            placeholder="Apt #, Suite, etc. (optional)"
                            value={address.address_line_2}
                            onChange={(e) =>
                                handleInputChange('address_line_2', e.target.value)
                            }
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    </Stack>
                    <Stack>
                        <TextField
                            label="City"
                            variant="standard"
                            fullWidth
                            placeholder="e.g. New York"
                            value={address.city}
                            onChange={(e) => handleInputChange('city', e.target.value)}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    </Stack>

                    {/* Conditionally render U.S. fields */}
                    {country === 'US' && (
                        <Stack>
                            <TextField
                                label="State"
                                variant="standard"
                                fullWidth
                                placeholder="e.g. NY"
                                value={address.state_abbreviation}
                                onChange={(e) =>
                                    handleInputChange('state_abbreviation', e.target.value)
                                }
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                            <TextField
                                label="Zip Code"
                                variant="standard"
                                fullWidth
                                placeholder="e.g. 10001"
                                value={address.zipcode}
                                onChange={(e) =>
                                    handleInputChange('zipcode', e.target.value)
                                }
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                        </Stack>
                    )}

                    {country === 'CA' && (
                        <Stack>
                            <TextField
                                label="Province"
                                variant="standard"
                                fullWidth
                                placeholder="e.g. AB"
                                value={address.state_abbreviation}
                                onChange={(e) =>
                                    handleInputChange('state_abbreviation', e.target.value)
                                }
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                            <TextField
                                label="Postal Code"
                                variant="standard"
                                fullWidth
                                placeholder="e.g. T2T 2T2"
                                value={address.zipcode}
                                onChange={(e) =>
                                    handleInputChange('zipcode', e.target.value)
                                }
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                        </Stack>
                    )}
                </Stack>
            )}

            <AppButton customStyles={{ marginTop: '1em' }} onClick={onSubmit} disabled={!canSubmit()}>
                Submit
            </AppButton>
        </AppContainer>
    );
}

export default AddUnitThree;
