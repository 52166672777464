const typeMapping = {
    0: 'Warning',
    1: 'Lockout'
};

export const getFaultName = (t, unitType, enumValue, typeNumber) => {
    const typeString = typeMapping[typeNumber];
    if (!unitType || !enumValue || !typeString) {
        return t('faults:unknownFault', 'Unknown Fault');
    }
    return t(`faults:${unitType}.${enumValue}.${typeString}`, 'Unknown Fault');
};
