import React, { useCallback, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {Typography, Stack} from '@mui/material';
import RemoteAccessIcon from "../../assets/Icons/RemoteAccessIcon";
import { useUser } from "../../contexts/UserProvider";
import { useUnits } from "../../contexts/UnitProvider";
import { CustomNameUtils } from "../../utilities/CustomNameUtils";
import AppContainer from "../../components/shared/AppContainer";
import AppHeader from "../../components/shared/AppHeader";
import UnitHeader from "../../components/unitSpecific/UnitHeader";
import UnitController from "../../components/unitSpecific/control/UnitController";
import AppCard from "../../components/shared/AppCard";
import AppLoader from "../../components/shared/AppLoader";
import ConAccordions from "../../components/unitSpecific/control/ConAccordions";

const StyledCard = styled(AppCard)({
    '& .MuiCardContent-root': {
        padding: 10,
    },
});

function ControllerScreen() {
    const { t } = useTranslation('controller');

    const { user, isContractor } = useUser();
    const { unitId } = useParams();
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source') || 'units';
    const groupId = searchParams.get('groupId');

    const navigate = useNavigate();
    const { unit, unitType, unitData, getSpecificUnitData, fetchUnitLink } = useUnits();
    const [hasFault, setHasFault] = useState();
    const isMetric = user?.metric;
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const backButtonRoute = source === 'groups' && groupId
        ? `/dashboard/my_units/${unitId}?source=groups&groupId=${groupId}`
        : `/dashboard/my_units/${unitId}?source=${source}`;

    // Fetching data, can move loading state to context file and avoid here
    const fetchData = useCallback(async () => {
        try {
            await getSpecificUnitData(unitId, true);
            setIsLoading(false);
            setHasError(false);
        } catch (error) {
            setIsLoading(false);
            setHasError(true);
        }
    }, [unitId, getSpecificUnitData]);

    useEffect(() => {
        fetchData();  // Initial data fetch
        const interval = setInterval(fetchData, 10000);
        return () => clearInterval(interval);
    }, [fetchData]);

    useEffect(() => {
        if (!isContractor) {
            navigate('/dashboard');
        } else {
            setHasFault(unit?.faults && unit?.faults === true);
        }
    }, [unit, isContractor, navigate]);

    if (isLoading) {
        return (
            <AppContainer justifyContent="center">
                <AppLoader />
            </AppContainer>
        );
    }

    // Better ways to handle this... UnitData issues can be resolved by handling how it is saved in the unitProvider fetch function...
    if (hasError || !unit || unitData === null || (Array.isArray(unitData) && unitData.length === 0)) {
        return (
            <AppContainer>
                <AppHeader backButtonRoute={backButtonRoute} />
                <Typography fontWeight="bold" align="center" color="secondaryText">
                    {t('connectionDropped')}
                </Typography>
            </AppContainer>
        );
    }

    const openRemoteAccess = () => {
        const newWindow = window.open('about:blank', '_blank');
        fetchUnitLink(unitId).then(link => {
            if (link) {
                newWindow.location.href = link;
            } else {
                newWindow.close();
            }
        });
    };

    return (
        <AppContainer>
            <AppHeader
                title={t('unitController')}
                rightIcon={(user.role === 2 || user.role === 3 || user.role === 4) && <RemoteAccessIcon size="30" color="#37589D" />}
                onClickRight={openRemoteAccess}
            />
            <UnitHeader unit={CustomNameUtils(unit, isContractor)} sn={unit.serial_number} status={hasFault ? 'ERROR' : 'OK'} />
            <ConAccordions unitType={unitType} unitData={unitData} isMetric={isMetric} />
            {unitType === 'AHU' && (
                <StyledCard type="good">
                    <Stack direction="row" spacing={1}>
                        <Typography >{t('blowerPWM')}</Typography>
                        <Typography fontWeight="bold">{unitData[74][0]} %</Typography>
                    </Stack>
                </StyledCard>
            )}
            <UnitController unitData={unitData} unitType={unitType} />
        </AppContainer>
    );
}

export default ControllerScreen;
