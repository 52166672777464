import React from 'react';
import {useTranslation} from "react-i18next";
import styled from '@emotion/styled';

import {Box, Stack, Typography} from '@mui/material';

import {useLocation, useNavigate} from 'react-router-dom';
import AppCard from "../../shared/AppCard";
import {convertAndDetermineColor} from "../../../utilities/TempUtils";
import {TimelineIcon} from "../../../assets/Icons/Icons";


const AppCardExtra = styled(AppCard)({
    '& .MuiCardContent-root:last-child': {
        padding: 15,
    },
});

const TempRow = React.memo(({ label, value, color, fontSize, showIcon, onIconClick }) => (
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography style={{ fontSize }}>
            {label}
        </Typography>
        <Stack direction="row" spacing={1}>
            <Typography fontWeight="700" style={{ fontSize, marginRight: '1em', color }}>
                {value}
            </Typography>
            {showIcon && onIconClick ? (
                <TimelineIcon onClick={onIconClick} color="success" style={{ cursor: 'pointer' }} />
            ) : (
                <Box ml={2} width="24px" />
            )}
        </Stack>
    </Stack>
));

function TempStatus({ unitData, isMetric, contractor, unitType, fontSize }) {
    const { t } = useTranslation('unitView');

    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const source = params.get('source') || 'units';
    const groupId = params.get('groupId');

    const handlePlotIcon = (path, title) => {
        const queryParams = new URLSearchParams();
        queryParams.set('title', title);
        if (source) {
            queryParams.set('source', source);
        }
        if (groupId) {
            queryParams.set('groupId', groupId);
        }
        navigate(`${path}?${queryParams.toString()}`);
    };

    const midTankTemp = parseFloat(unitData[20][0]);
    const isMidTankValid = !isNaN(midTankTemp) && midTankTemp !== -40;

    if (unitType === 'GAHP') {
        const tempReadings = [
            {
                label: contractor ? t('ambient') : t('outdoorAir'),
                dataIndex: 10,
                extraParam: true,
                plotTitle: 'Ambient',
            },
            {
                label: t('hydronicSupply'),
                dataIndex: 12,
                plotTitle: 'Hydronic Supply',
            },
            {
                label: t('hydronicReturn'),
                dataIndex: 16,
                plotTitle: 'Hydronic Return',
                contractorOnly: true,
            },
            {
                label: t('midTank'),
                dataIndex: 20,
                plotTitle: 'Mid Tank',
                showCondition: isMidTankValid,
            },
            {
                label: t('hydronicFlow'),
                dataIndex: 17,
                plotTitle: 'Hydronic Flow',
                isFlow: true, // special handling
            },
        ];

        return (
            <Stack spacing={0.5}>
                <Typography>
                    Temperatures:
                </Typography>
                <AppCardExtra>
                    {tempReadings.map((reading, index) => {
                        if (reading.contractorOnly && !contractor) {
                            return null;
                        }
                        if (reading.showCondition === false) {
                            return null;
                        }

                        if (reading.isFlow) {
                            // Special handling for Hydronic Flow
                            return (
                                <TempRow
                                    key={index}
                                    label={reading.label}
                                    value={`${unitData[reading.dataIndex][0]} GPM`}
                                    fontSize={fontSize}
                                    showIcon={contractor}
                                    onIconClick={() => handlePlotIcon('plot', reading.plotTitle)}
                                />
                            );
                        } else {
                            const tempData = convertAndDetermineColor(
                                unitData[reading.dataIndex][0],
                                isMetric,
                                reading.extraParam
                            );
                            return (
                                <TempRow
                                    key={index}
                                    label={reading.label}
                                    value={tempData.convertedValue}
                                    color={tempData.color}
                                    fontSize={fontSize}
                                    showIcon={contractor}
                                    onIconClick={() => handlePlotIcon('plot', reading.plotTitle)}
                                />
                            );
                        }
                    })}
                </AppCardExtra>
            </Stack>
        );
    } else {
        // AHU unit
        const tempData = convertAndDetermineColor(unitData[42][0], isMetric);
        return (
            <Stack spacing={0.5}>
                <Typography>
                    {t('temperatures')}
                </Typography>
                <AppCardExtra>
                    <TempRow
                        label={t('supplyAir')}
                        value={tempData.convertedValue}
                        color={tempData.color}
                        fontSize={fontSize}
                        showIcon={contractor}
                        onIconClick={() => handlePlotIcon('plot', 'Supply Air')}
                    />
                </AppCardExtra>
            </Stack>
        );
    }
}

export default TempStatus;
