import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';

import AppCard from '../../shared/AppCard';

const InvertedCard = styled(AppCard)({
    backgroundColor: '#37589D',
    color: 'white',
    borderColor: 'transparent',
});

const getParameterValue = (parameters, index) => {
    return parameters?.[index]?.[0] ?? null;
};

// TODO: Remove fontSize prop, just make static.
function StateStatus({ unitData, unitType, fontSize }) {
    const { t } = useTranslation('stateStatus');

    const rows = [];
    if (unitType === 'GAHP') {
        const statusValue = getParameterValue(unitData, 67);
        const translatedStatus = statusValue
            ? t(`values.GAHP.${statusValue}`, statusValue)
            : t('unknownState', 'Unknown State');

        rows.push({
            label: t('labels.status'),
            value: translatedStatus.replace('GAHP', '').trim(),
        });
    }

    const operatingModeIndex = unitType === 'GAHP' ? 66 : 65;
    const operatingModeValue = getParameterValue(unitData, operatingModeIndex);
    const translatedOperatingMode = operatingModeValue
        ? t(`values.${unitType}.${operatingModeValue}`, operatingModeValue)
        : t('unknownState', 'Unknown State');

    rows.push({
        label: t('labels.operatingMode'),
        value: operatingModeValue ? translatedOperatingMode.replace(unitType, '').trim() : t('unknownState', 'Unknown State'),
    });

    if (unitType === 'AHU') {
        const blowerStatusValue = getParameterValue(unitData, 57);
        const translatedBlowerStatus = blowerStatusValue
            ? t(`values.AHU.${blowerStatusValue}`, blowerStatusValue)
            : t('unknownState', 'Unknown State');

        rows.push({
            label: t('labels.blowerStatus'),
            value: translatedBlowerStatus,
        });
    }

    return (
        <Stack spacing={0.5}>
            <Typography>{t('labels.condition')}</Typography>
            <InvertedCard>
                <Stack spacing={1}>
                    {rows.map((row, index) => (
                        <Stack
                            key={index}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography style={{ fontSize }}>
                                {row.label}
                            </Typography>
                            <Typography fontWeight="bold" style={{ fontSize }}>
                                {row.value}
                            </Typography>
                        </Stack>
                    ))}
                </Stack>
            </InvertedCard>
        </Stack>
    );
}

export default StateStatus;
