import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import {
    Typography,
    Box,
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
    Divider,
} from '@mui/material';

import {ArrowForwardIosSharpIcon} from '../../../assets/Icons/Icons';
import StatusIndicator from './StatusIndicator';
import { convertAndDetermineColor } from '../../../utilities/TempUtils';

const NestedAccordion = styled(MuiAccordion)({
    boxShadow: 'none',
    border: 'none',
    '&:before': {
        display: 'none',
    },
});

const NestedAccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}{...props}/>
))({
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
});

const GAHP_THERMOSTAT_INDICES = [0, 1, 2, 3, 4, 5];
const AHU_THERMOSTAT_INDICES_PART1 = [0, 1, 2, 3, 4, 5, 6, 7, 8];
const AHU_THERMOSTAT_INDICES_PART2 = [15, 16, 17, 18, 19, 20, 21, 22];
const GAHP_RELAY_INDICES = [46, 52, 40, 44, 50, 32, 76];
const AHU_TEMPERATURE_INDICES = [40, 41, 42, 43, 45, 47, 48];

// TODO: Move into its own file...
function StatusItem({ label, value, isOn, color }) {
    const { t } = useTranslation('controller');

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="body2">{t(label)}</Typography>
            {/*Diff. between value or indicator here*/}
            {value !== undefined ? (
                <Typography fontWeight="bold" style={{ color }}>
                    {value}
                </Typography>
            ) : (
                <StatusIndicator isOn={isOn} />
            )}
        </Box>
    );
}

function ConAccordions({ unitType, unitData, isMetric }) {
    const { t } = useTranslation('controller');

    const [nestedExpanded, setNestedExpanded] = useState(false);
    const handleNestedChange = (panel) => (event, isExpanded) => {
        setNestedExpanded(isExpanded ? panel : false);
    };

    const renderStatusItems = (indices, unitData, renderItem) => {
        return indices.map((index) => {
            const item = unitData[index];
            if (!item) return null;
            return renderItem(item, index);
        });
    };

    return (
        <Box mb={1}>
            <Typography mb={0.5}>{t('condition')}</Typography>
            <MuiAccordion sx={{ boxShadow: 'none', border: `1px solid #4EB6B5` }}>
                <MuiAccordionDetails sx={{ padding: '0'}}>
                    {/* First Nested Accordion */}
                    <NestedAccordion
                        expanded={nestedExpanded === 'nestedPanel1'}
                        onChange={handleNestedChange('nestedPanel1')}
                    >
                        <NestedAccordionSummary id="nestedPanel1d-header">
                            <Typography>
                                {unitType === 'GAHP' ? t('thermostatInputs') : t('thermostatInputsOutputs')}
                            </Typography>
                        </NestedAccordionSummary>
                        <MuiAccordionDetails>
                            {unitType === 'GAHP' && unitData && renderStatusItems(GAHP_THERMOSTAT_INDICES, unitData, (item, index) => {
                                const [value, label] = item;
                                const isOn = value === 1;
                                return <StatusItem key={index} label={label} isOn={isOn} />;
                            })}
                            {unitType === 'AHU' && unitData && (
                                <Box display="flex" justifyContent="space-between">
                                    <Box>
                                        {renderStatusItems(AHU_THERMOSTAT_INDICES_PART1, unitData, (item, index) => {
                                            const [value, label] = item;
                                            const isOn = value === 1;
                                            return <StatusItem key={index} label={label} isOn={isOn} />;
                                        })}
                                    </Box>
                                    <Box>
                                        {renderStatusItems(AHU_THERMOSTAT_INDICES_PART2, unitData, (item, index) => {
                                            const [value, label] = item;
                                            const isOn = value === 1;
                                            return <StatusItem key={index} label={label} isOn={isOn} />;
                                        })}
                                    </Box>
                                </Box>
                            )}
                        </MuiAccordionDetails>
                    </NestedAccordion>
                    <Divider />
                    <NestedAccordion expanded={nestedExpanded === 'nestedPanel2'} onChange={handleNestedChange('nestedPanel2')}>
                        <NestedAccordionSummary id="nestedPanel2d-header">
                            <Typography>
                                {unitType === 'GAHP' ? t('relays') : t('allTemperatures')}
                            </Typography>
                        </NestedAccordionSummary>
                        <MuiAccordionDetails>
                            {unitType === 'GAHP' && unitData && renderStatusItems(GAHP_RELAY_INDICES, unitData, (item, index) => {
                                const [value, label] = item;
                                if (index === 76) {
                                    return <StatusItem key={index} label={label} value={`${value} %`} />;
                                } else {
                                    const isOn = value === 1;
                                    return <StatusItem key={index} label={label} isOn={isOn} />;
                                }
                            })}
                            {unitType === 'AHU' && unitData && renderStatusItems(AHU_TEMPERATURE_INDICES, unitData, (item, index) => {
                                const [value, label] = item;
                                const { convertedValue, color } = convertAndDetermineColor(value, isMetric);
                                return <StatusItem key={index} label={label} value={convertedValue} color={color} />;
                            })}
                        </MuiAccordionDetails>
                    </NestedAccordion>
                </MuiAccordionDetails>
            </MuiAccordion>
        </Box>
    );
}

export default ConAccordions;
