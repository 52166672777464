import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { Box, Card, Stack, useMediaQuery } from '@mui/material';

import AppHeader from '../../components/shared/AppHeader';
import AppContainer from '../../components/shared/AppContainer';
import RegisterOne from '../../components/registration/RegisterOne';
import RegisterTwo from '../../components/registration/RegisterTwo';
import RegisterThree from '../../components/registration/RegisterThree';
import RegisterConPassword from '../../components/registration/RegisterConPassword';
import Register2FA from '../../components/registration/Register2FA';
import { RegistrationProvider, RegistrationContext } from '../../contexts/RegistrationContext';

import Logo from '../../assets/Images/anesi_Logo.png';

const ResponsiveContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

const DesktopContainer = styled(Card)(({ theme }) => ({
    width: '40%',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
    padding: '30px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px solid rgba(145, 158, 171, 0.1)',
}));

function RegisterScreen() {
    return (
        <RegistrationProvider>
            <RegisterContent />
        </RegistrationProvider>
    );
}

function RegisterContent() {
    const { t } = useTranslation('register');
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const { step } = useContext(RegistrationContext);

    const steps = [
        <RegisterOne key={0} />,
        <RegisterTwo key={1} />,
        <Register2FA key={2} />,
        <RegisterConPassword key={3} />,
        <RegisterThree key={4} />,
    ];

    return (
        <AppContainer>
            {!isDesktop && <AppHeader title={t('registerScreen.title')} backButtonRoute="/login" />}
            <Box display="flex" flexGrow={1} flexDirection="column">
                <img
                    src={Logo}
                    alt="Anesi Logo"
                    style={{ width: isDesktop ? '5em' : '20%', alignSelf: 'center', margin: '1.5em' }}
                />
                <ResponsiveContainer>
                    {isDesktop ? (
                        <DesktopContainer>
                            <Box width="100%">{steps[step]}</Box>
                        </DesktopContainer>
                    ) : (
                        <Stack width="100%">{steps[step]}</Stack>
                    )}
                </ResponsiveContainer>
            </Box>
        </AppContainer>
    );
}

export default RegisterScreen;
