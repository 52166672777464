import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Joyride, { STATUS } from 'react-joyride';

function getTourSteps(t) {
    return [
        {
            target: 'body',
            content: t('welTour'),
            placement: 'center',
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
        },
        {
            target: '.my-account-button',
            content: t('accTour'),
            placement: 'bottom',
            disableBeacon: true,
        },
        {
            target: '.settings-button',
            content: t('settTour'),
            placement: 'bottom',
        },
        {
            target: '.clickable-card',
            content: t('unitsTour'),
            placement: 'bottom',
        },
        {
            target: '.snapshot',
            content: t('snapTour'),
            placement: 'bottom',
        },
    ];
}

function DashboardTour() {
    const { t } = useTranslation('dashboard');
    const tourSteps = getTourSteps(t);

    const [runTour, setRunTour] = useState(false);

    useEffect(() => {
        const hasTakenTour = localStorage.getItem('hasTakenTour') === 'true';
        if (!hasTakenTour) {
            setRunTour(true);
        }
    }, []);

    const handleJoyrideCallback = (data) => {
        const { status } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            localStorage.setItem('hasTakenTour', 'true');
            setRunTour(false);
        }
    };

    return (
        <Joyride
            continuous
            run={runTour}
            steps={tourSteps}
            callback={handleJoyrideCallback}
            scrollToFirstStep
            showProgress
            showSkipButton
            hideCloseButton="true"
            locale={{
                back: t('back'),
                close: t('close'),
                last: t('close'),
                next: t('next'),
                skip: t('skip'),
            }}
            styles={{
                options: {
                    primaryColor: '#4EB6B5',
                    fontFamily: 'Jura',
                    textColor: '#37589D',
                    arrowColor: '#4EB6B5',
                },
                tooltip: {
                    backgroundColor: '#fff',
                    borderRadius: '0.75em',
                },
                tooltipContent: {
                    fontFamily: 'Jura',
                    fontWeight: 'bold',
                },
                buttonNext: {
                    backgroundColor: '#4EB6B5',
                    border: '1px',
                    borderColor: 'transparent',
                    fontSize: '12px'
                },
                buttonBack: {
                    color: '#37589D',
                    fontFamily: 'Jura',
                    fontWeight: 'bold',
                    marginRight: '0.75em',
                    fontSize: '12px'

                },
                buttonSkip: {
                    fontFamily: 'Jura'
                },
            }}
        />
    );
}

export default DashboardTour;
