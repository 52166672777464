import React, {useContext, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {useNavigate} from "react-router-dom";

import styled from "@emotion/styled";
import {Avatar, Box, DialogContentText, Stack, Typography} from "@mui/material";
import * as Yup from "yup";
import {Form, Formik} from "formik";

import {Email, Person, Phone, Work} from "@mui/icons-material";
import {RegistrationContext} from "../../contexts/RegistrationContext";
import AppTextInput from "../shared/AppTextInput";
import AppButton from "../shared/AppButton";
import AppDialog from "../shared/AppDialog";
import PhoneNumberInput from "../AppPhoneInput";
import AppCard from "../shared/AppCard";

// TODO: Move this into dedicated utilities file in order to not have to declare every file. Re-do this file, modularity...

const InfoCard = styled(AppCard)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '1em',
    position: 'relative',
    overflow: 'visible',
});

const AvatarIcon = styled(Avatar)({
    position: 'absolute',
    top: '-20px',
    right: '-10px',
    width: '50px',
    height: '50px',
    backgroundColor: '#4EB6B5',
    color: 'white',
    fontSize: '1.5em',
});

const ButtonContainer = styled(Box)({
    display: 'flex',
    marginTop: '0.25em',
    marginBottom: '1em',
});

const regSchema = (t) => Yup.object().shape({
    firstName: Yup.string().required(t('registerTwo.firstNameReq')),
    lastName: Yup.string().required(t('registerTwo.lastNameReq')),
    phoneNumber: Yup.string().matches(/^\(\d{3}\) \d{3}-\d{4}$/, t('registerTwo.phoneNumFormat')).required(t('registerTwo.phoneNumberReq'))
});


const InfoItem = ({ label, Icon, value }) => (
    <Box display="flex" alignItems="center" gap={2} >
        <Icon fontSize="small" color="secondary" />
        <Stack direction="column">
            <Typography variant="body2" color="gray">{label}</Typography>
            <Typography fontWeight="bold">{value}</Typography>
        </Stack>
    </Box>
);

function RegisterTwo() {
    const { t } = useTranslation('register');

    const navigate = useNavigate();
    const { formState, setFormState, setStep } = useContext(RegistrationContext);
    const { userType } = formState;

    const [showOptInModal, setShowOptInModal] = useState(false);
    const [showSupport, setShowSupport] = useState(false);

    const handleContinue = (values) => {
        const { role } = formState;
        setFormState(prevState => ({...prevState, ...values, role}));

        if (userType === 'Contractor') {
            setStep(2);
        } else {
            setShowOptInModal(true);
        }
    };

    const handleModalClose = () => {
        setShowOptInModal(false);
        setStep(4);
    };

    return (
        <>
            {userType === 'Contractor' ? (
                <>
                    <Stack mb={2}>
                        <Typography variant="h5" >{t('registerTwo.hiName', { firstName: formState.firstName })}</Typography>
                        <Typography color="secondaryText" >{t('registerTwo.doesInfoLookCorrect')}</Typography>
                    </Stack>
                    <InfoCard type="good">
                        <AvatarIcon src="/static/images/avatar/1.jpg" alt={formState.firstName} />
                        <Stack spacing={1}>
                            <InfoItem
                                label={t('registerTwo.name')}
                                Icon={Person}
                                value={`${formState.firstName} ${formState.lastName}`}
                            />
                            <InfoItem
                                label={t('registerTwo.employer')}
                                Icon={Work}
                                value={formState.companyName}
                            />
                            <InfoItem
                                label={t('registerScreen.phoneNumber')}
                                Icon={Phone}
                                value={formState.phoneNumber}
                            />
                            <InfoItem
                                label={t('registerScreen.email')}
                                Icon={Email}
                                value={formState.email}
                            />
                        </Stack>
                    </InfoCard>
                    <ButtonContainer flexDirection="column">
                        <AppButton buttonVariant="outlined" variant="secondary" onClick={() => setShowSupport(true)}>{t('registerScreen.no')}</AppButton>
                        <AppButton onClick={() => setStep(2)}>{t('registerScreen.yes')}</AppButton>
                    </ButtonContainer>
                    <AppDialog
                        open={showSupport}
                        title={t('registerTwo.supportNeededTitle')}
                        handleClose={() => setShowSupport(false)}
                        handleConfirm={() => navigate('/')}
                        confirmText={t('registerScreen.continue')}
                    >
                        <DialogContentText fontWeight="600">
                            {t('registerTwo.supportNeededContent')}
                        </DialogContentText>
                    </AppDialog>
                </>
            ) : (
                <>
                    <Stack alignItems="center" spacing={1}>
                        <Typography variant="h6">{t('registerTwo.andYouAre')}</Typography>
                        <Typography variant="subtitle1" color="secondaryText" textAlign="center">{t('registerTwo.finishByInputtingInfo')}</Typography>
                    </Stack>
                    <Formik initialValues={formState} validationSchema={regSchema(t)} onSubmit={handleContinue}>
                        <Form>
                            <Stack direction="column" spacing={2} mb={2}>
                                <AppTextInput label={t('registerScreen.firstName')} name="firstName"/>
                                <AppTextInput label={t('registerScreen.lastName')} name="lastName"/>
                                <PhoneNumberInput placeholder={t('registerScreen.phoneNumber')} />
                                <Typography variant="caption" >
                                    {t('registerTwo.optInMessage')}
                                </Typography>
                            </Stack>
                            <AppButton variant="primary" type="submit">{t('registerScreen.continue')}</AppButton>
                        </Form>
                    </Formik>

                    <AppDialog
                        open={showOptInModal}
                        title={t('registerTwo.optInTitle')}
                        handleClose={handleModalClose}
                        handleConfirm={handleModalClose}
                        confirmText={t('registerTwo.iAgree')}
                        cancelText={t('registerScreen.no')}
                    >
                        <DialogContentText fontWeight="600">
                            {t('registerTwo.optInContent')}
                        </DialogContentText>
                        <ul>
                            <li style={{ fontWeight: '500', fontFamily: 'Jura', fontSize: '0.95em', color: '#37589D'}}>Account updates</li>
                            <li style={{ fontWeight: '500', fontFamily: 'Jura', fontSize: '0.95em', color: '#37589D'}}>Service alerts</li>
                            <li style={{ fontWeight: '500', fontFamily: 'Jura', fontSize: '0.95em', color: '#37589D'}}>Exclusive offers</li>
                        </ul>
                        <DialogContentText fontWeight="600">
                            {t('registerTwo.optInFooter')}
                        </DialogContentText>
                    </AppDialog>

                </>
            )}
        </>
    );
}

export default RegisterTwo;
