import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import AppContainer from '../../components/shared/AppContainer';
import {
    Grid,
    Typography,
    Box,
    IconButton,
    FormControlLabel,
    Checkbox, TextField, Stack, Divider
} from '@mui/material';

import {SearchIcon, CheckIcon, EditIcon, DeleteIcon, AddIcon, ArrowBackIosIcon, CreateNewFolderIcon } from '../../assets/Icons/Icons.js';
import AppHeader from '../../components/shared/AppHeader';
import AppSearchBar from '../../components/shared/AppSearchBar';
import AppDrawer from "../../components/shared/AppDrawer";
import UnitCard from '../../components/unitSpecific/UnitCard';
import useGroups from '../../hooks/groups/useGroups';
import GroupCard from "../../components/groups/GroupCard";
import ParentGroupCard from "../../components/groups/ParentGroupCard";
import CreateParentDialog from "../../components/groups/CreateParentDialog";
import UseParentGroups from "../../hooks/groups/useParentGroups";
import EditParentDialog from "../../components/groups/EditParentDialog";
import {useUser} from "../../contexts/UserProvider";
import {useUnits} from "../../contexts/UnitProvider";
import { CreateGroupContent } from "../../components/unitSpecific/UnitDrawerContent";

function GroupsScreen() {
    const { t } = useTranslation('groups');
    const navigate = useNavigate();
    const location = useLocation();

    const {isContractor} = useUser();
    const { parentGroups, fetchParentGroups, deleteParentGroup, editParentGroup } = UseParentGroups();
    const { unitGroups } = useUnits();
    const [expandedCardId, setExpandedCardId] = useState(null);

    const [confirmCreateDialogOpen, setConfirmCreateDialogOpen] = useState(false);

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [parentGroupToEdit, setParentGroupToEdit] = useState(null);

    const handleLongPress = (parentGroup) => {
        setParentGroupToEdit(parentGroup);
        setEditDialogOpen(true);
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        setParentGroupToEdit(null);
    };

    const handleSaveParentGroup = async (newName, groupsToAdd, groupsToRemove) => {
        if (parentGroupToEdit) {
            await editParentGroup(parentGroupToEdit.id, groupsToAdd, groupsToRemove, newName);
            await fetchParentGroups();
            handleEditDialogClose();
        }
    };

    const handleCreateDialog = (isOpen) => {
        setConfirmCreateDialogOpen(isOpen);
    }
    const handleCardClick = (id) => {
        setExpandedCardId(expandedCardId === id ? null : id);
    }

    const deleteGroup = async (groupId) => {
        console.log("deleteGroup", groupId);
        await deleteParentGroup(groupId);
        fetchParentGroups();
    }

    const handleGroupClick = (group) => {
        handleGroupUnitsDrawer(group);
    };

    const {
        searchGroupTerm,
        setSearchGroupTerm,
        filteredGroups,
        createGroupDrawerOpen,
        groupName,
        setGroupName,
        newGroupName,
        setNewGroupName,
        isEditingGroupName,
        selectedGroupUnits,
        groupUnitsDrawerOpen,
        selectedGroup,
        selectedAvailableUnits,
        addingUnits,
        setAddingUnits,
        filteredCreateGroupUnits,
        availableUnitsTerm,
        setAvailableUnitsTerm,
        filteredAvailableUnits,

        handleOnDragEnd,
        handleCreateGroupDrawer,
        toggleUnitSelection,
        handleSaveGroup,
        handleGroupUnitsDrawer,
        handleCloseGroupUnitsDrawer,
        handleEditGroupName,
        handleSaveGroupName,
        handleDeleteGroup,
        handleAddUnits,
        handleFinishAddUnits,
        handleRemoveUnit,
        handleToggleUnitSelection,
    } = useGroups(fetchParentGroups);


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const groupId = params.get('groupId');
        if (groupId) {
            const group = unitGroups.find(g => g.id.toString() === groupId);
            if (group) {
                handleGroupUnitsDrawer(group);
            } else {
                navigate('/dashboard/my_units/groups');
            }
        }
    }, [location.search]);

    return (
        <AppContainer>
            <AppHeader
                backButtonRoute="/dashboard/my_units"
                title={t('unitGroups')}
                onClickRight={handleCreateGroupDrawer}
                rightIcon={
                    <CreateNewFolderIcon
                        style={{ cursor: "pointer" }} // may not need cursor see appheader.
                    />
                }
            />

            {unitGroups.length > 0 ? (
                <>
                    <AppSearchBar
                        value={searchGroupTerm}
                        onChange={e => setSearchGroupTerm(e.target.value)}
                        placeholder={t('searchGroupsPlaceholder')}
                        endAdornment={<SearchIcon color="secondary" />}
                    />

                    {parentGroups.length > 0 && (
                        <>
                            <Typography my={1} variant="h6" color="secondaryText.main" >
                                {t('parentGroups')}
                            </Typography>
                            <Grid container spacing={0.5} mb={1}>
                                {parentGroups.map((parentGroup) => (
                                    <Grid item xs={expandedCardId === parentGroup.id ? 12 : 4} key={parentGroup.id}>
                                        <ParentGroupCard
                                            groupName={parentGroup.name}
                                            nGroups={parentGroup.groups.length}
                                            expanded={expandedCardId === parentGroup.id}
                                            onClick={() => handleCardClick(parentGroup.id)}
                                            onDelete={()=> deleteGroup(parentGroup.id)}
                                            groups={parentGroup.groups}
                                            groupClick={handleGroupClick}
                                            onLongPress={() => handleLongPress(parentGroup)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>

                    )}

                    <Stack direction="row" justifyContent="space-between" alignItems="center" my={1}>
                        <Typography variant="subtitle1" color="secondaryText.main" fontWeight="bold" >
                            {t('allGroups')}
                        </Typography>
                        <Typography onClick={() => handleCreateDialog(true)}  variant="body2" color="secondary" fontWeight="bold" style={{ cursor: "pointer" }} >
                            {t('createParentGroups')}
                        </Typography>
                    </Stack>


                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="groups">
                            {(provided) => (
                                <Grid
                                    container
                                    spacing={0.5}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {filteredGroups.map((group, index) => (
                                        <Draggable
                                            key={group.id}
                                            draggableId={group.id.toString()}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <Grid
                                                    item
                                                    xs={6}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <GroupCard groupName={group.name} nUnits={group.units.length} onClick={() => handleGroupUnitsDrawer(group)} />
                                                </Grid>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Grid>
                            )}
                        </Droppable>
                    </DragDropContext>
                </>
            ) : (
                <Typography variant="subtitle1" textAlign="center" fontWeight="bold" mt={1} >
                    {t('createFirstGroup')}
                </Typography>
            )}

            <AppDrawer
                open={groupUnitsDrawerOpen}
                onClose={handleCloseGroupUnitsDrawer}
                title={
                    <Box display="flex" alignItems="center" gap={1}>
                        {addingUnits ? (
                            <>
                                <IconButton
                                    color="info"
                                    onClick={() => setAddingUnits(false)}
                                >
                                    <ArrowBackIosIcon />
                                </IconButton>
                            </>
                        ) : (
                            <>
                                <IconButton onClick={isEditingGroupName ? handleSaveGroupName : handleEditGroupName}>
                                    {isEditingGroupName ? <CheckIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                                </IconButton>
                                {isEditingGroupName ? (
                                    <TextField
                                        value={newGroupName}
                                        onChange={e => setNewGroupName(e.target.value)}
                                        variant="standard"
                                        fullWidth
                                    />
                                ) : (
                                    <Typography variant="subtitle1" fontWeight="700" flex={1}>
                                        {t('unitsInGroup', { groupName: selectedGroup?.name })}
                                    </Typography>
                                )}
                            </>
                        )}
                    </Box>
                }
            >
                {!addingUnits ? (
                    <>
                        {selectedGroup && selectedGroup.units.length > 0 ? (
                            <Box display="flex" flexDirection="column" gap={1} mt={1} maxHeight="90%" overflow="auto">
                                {selectedGroup.units.map(unit => (
                                    <UnitCard
                                        key={unit.id}
                                        unit={unit}
                                        contractor={isContractor}
                                        navigate={navigate}
                                        source="groups"
                                        groupId={selectedGroup.id}
                                        setDrawerOpen={handleCloseGroupUnitsDrawer}
                                        swipeIcon={<DeleteIcon />}
                                        onSwipe={() => handleRemoveUnit(unit.id)}
                                    />
                                ))}
                            </Box>
                        ) : (
                            <Typography>{t('noUnitsInGroup')}</Typography>
                        )}

                        <Grid container alignItems="center" justifyContent="space-between" mt={1}>
                            <Grid item>
                                <IconButton color="success" onClick={handleAddUnits}>
                                    <AddIcon />
                                    <Typography variant="body2">
                                        {t('addUnit')}
                                    </Typography>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton color="error" onClick={() => handleDeleteGroup(selectedGroup.id)}>
                                    <DeleteIcon />
                                    <Typography variant="body2" >
                                        {t('deleteGroup')}
                                    </Typography>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Typography variant="subtitle1">{t('selectUnitsToAdd')}</Typography>
                        <AppSearchBar
                            placeholder={t('searchUnitsPlaceholder')}
                            value={availableUnitsTerm}
                            onChange={e => setAvailableUnitsTerm(e.target.value)}
                            endAdornment={<SearchIcon color="secondary" />}

                            //////


                        />
                        {filteredAvailableUnits.length === 0 ? (
                            <Typography mt={2} textAlign="center" variant="h6">{t('noAvailableUnits')}</Typography>
                        ) : (
                            <Box mt={2}>
                                {filteredAvailableUnits.map(unit => (
                                    <Box key={unit.id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedAvailableUnits.includes(unit.id)}
                                                    onChange={() => handleToggleUnitSelection(unit.id)}
                                                    size="small"
                                                    color="success"
                                                />
                                            }
                                            label={`SN: ${unit.serial_number}`}
                                            sx={{
                                                '.MuiFormControlLabel-label': {
                                                    fontSize: '0.9em',
                                                },
                                            }}
                                        />
                                        <Divider />
                                    </Box>
                                ))}
                            </Box>
                        )}
                        {selectedAvailableUnits.length > 0 && (
                            <IconButton
                                color="success"
                                onClick={handleFinishAddUnits}
                            >
                                <CheckIcon />
                                <Typography variant="body2" ml={1}>
                                    {t('finishAdding')}
                                </Typography>
                            </IconButton>
                        )}
                    </>
                )}
            </AppDrawer>

            <AppDrawer
                open={createGroupDrawerOpen}
                onClose={handleCreateGroupDrawer}
                title={t('createNewGroup')}
            >
                <CreateGroupContent
                    groupName={groupName}
                    setGroupName={setGroupName}
                    filteredGroupUnits={filteredCreateGroupUnits}
                    selectedGroupUnits={selectedGroupUnits}
                    toggleUnitSelection={toggleUnitSelection}
                    handleSaveGroup={handleSaveGroup}
                    setSearchGroupTerm={setSearchGroupTerm}
                    searchGroupTerm={searchGroupTerm}
                />
            </AppDrawer>

            <CreateParentDialog
                open={confirmCreateDialogOpen}
                handleClose={() => handleCreateDialog(false)}
                unitGroups={unitGroups}
                fetchParentGroups={fetchParentGroups}
            />
            <EditParentDialog
                open={editDialogOpen}
                handleClose={handleEditDialogClose}
                parentGroup={parentGroupToEdit}
                handleSave={handleSaveParentGroup}
                allGroups={filteredGroups}
            />

        </AppContainer>
    );
}

export default GroupsScreen;
