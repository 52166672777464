import styled from "@emotion/styled";
import {Stack} from "@mui/material";
import {useTranslation} from "react-i18next";

import {InfoOutlinedIcon} from "../../assets/Icons/Icons";
import AppCard from "../../components/shared/AppCard";
import {getFaultName} from "../../utilities/FaultNameUtil";

const StyledCard = styled(AppCard)({
    '& .MuiCardContent-root': {
        padding: 12,
    }
});

function FaultItem({ fault, isContractor, onFaultClick, unitType }) {
    const { t } = useTranslation('faults');
    const translatedFaultName = getFaultName(t, unitType, fault.enum, fault.type);

    return (
        <StyledCard key={fault.id} type={fault.active ? 'bad' : 'good'}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" fontSize="14px">
                {translatedFaultName}
                {isContractor && (
                    <InfoOutlinedIcon
                        style={{ color: fault.active ? '#B1494A' : '#4EB6B5', cursor: 'pointer' }}
                        onClick={() => onFaultClick(fault)}
                    />
                )}
            </Stack>
        </StyledCard>
    );
}

export default FaultItem;
