import React from 'react';
import { useTranslation } from 'react-i18next';
import {Dialog, DialogContent, DialogActions, Typography, Slide, DialogTitle, IconButton} from "@mui/material";

import {CloseIcon, CheckIcon} from '../../assets/Icons/Icons.js';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} timeout={500}/>;
});

function AppPop({title, children, handleOpen, handleClose = false, showActions = false, handleConfirm, confirmationState}) {
    const { t } = useTranslation('alerts');

    return (
        <Dialog open={handleOpen} TransitionComponent={Transition}>
            {title && (
                <DialogTitle align="center">
                    {title}
                </DialogTitle>
            )}
            <DialogContent>
                    {children}
            </DialogContent>
            {showActions && (
                <DialogActions >
                    {confirmationState === 'confirm' && (
                        <IconButton onClick={handleClose} style={{ flexDirection: 'column' }}>
                            <CloseIcon color="error"  />
                            <Typography fontWeight="bold" variant="caption">
                                {t('cancel')}
                            </Typography>
                        </IconButton>
                    )}
                    <IconButton onClick={confirmationState === 'confirm' ? handleConfirm : handleClose} style={{ flexDirection: 'column' }}>
                        {confirmationState === 'confirm' ? <CheckIcon  color="success"  /> : <CheckIcon   color="success" />}
                        <Typography variant="caption" fontWeight="bold">
                            {confirmationState === 'confirm' ? t('confirm') : t('close')}
                        </Typography>
                    </IconButton>
                </DialogActions>
            )}
        </Dialog>
    );
}

export default AppPop;
