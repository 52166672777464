import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';

import styled from "@emotion/styled";
import {Drawer, Tabs, Tab, Paper} from "@mui/material";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

import AppContainer from "../../components/shared/AppContainer";
import AppHeader from "../../components/shared/AppHeader";
import AppButton from "../../components/shared/AppButton";
import UnitHeader from "../../components/unitSpecific/UnitHeader";
import AlertInfo from "../../components/alerts/AlertInfo";
import AppPop from "../../components/alerts/AppPop";
import {useUnits} from "../../contexts/UnitProvider";
import {useApi} from "../../contexts/ApiProvider";
import AppLoader from "../../components/shared/AppLoader";
import {useAlert} from "../../contexts/AlertsProvider";
import {useUser} from "../../contexts/UserProvider";
import {CustomNameUtils} from "../../utilities/CustomNameUtils";
import AlertsTabPanel from "../../components/alerts/AlertsTabPanel";
import {clearFaults} from "../../services/api/alerts/alertsApi";
import {getFaultName} from "../../utilities/FaultNameUtil";

// custom component
const AlertDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
        background: 'linear-gradient(#FCFCFF, #FCFCFF) padding-box, linear-gradient(to right, #37589D, #4EB6B5) border-box',
        borderTop: '7px solid transparent',
        borderRadius: '25px 25px 0 0',
        height: '50%',
    },
})

const forNonContractor = [11,14,15,19,26];

function AlertsScreen() {
    const { t } = useTranslation(['alerts', 'faults']);

    const api = useApi();
    const alert = useAlert();
    const navigate = useNavigate();
    const {isContractor} = useUser();
    const [selectedFault, setSelectedFault] = useState(null);
    const {unitId} = useParams()

    const [searchParams] = useSearchParams();
    const source = searchParams.get('source') || 'units';
    const groupId = searchParams.get('groupId');

    const {unit, unitType, setUnit, getSpecificUnitData, getSpecificUnitFaults} = useUnits();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [clearErrorDialogOpen, setClearErrorDialogOpen] = useState(false);
    const [hasFault, setHasFault] = useState(false);
    const [activeFaults, setActiveFaults] = useState([]);
    const [nonActiveFaults, setNonActiveFaults] = useState([]);
    const handleDrawer = () => {setDrawerOpen(false); };
    const [loading, setLoading] = useState(false);
    const [confirmationState, setConfirmationState] = useState('confirm');
    const [activeTab, setActiveTab] = useState(0);

    const toggleClearErrorDialog = () => setClearErrorDialogOpen(!clearErrorDialogOpen);

    const backButtonRoute = source === 'groups' && groupId
        ? `/dashboard/my_units/${unitId}?source=groups&groupId=${groupId}`
        : `/dashboard/my_units/${unitId}?source=${source}`;


    useEffect(() => {
        async function fetchUnit() {
            setLoading(true);
            try {
                const fetchedUnit = await getSpecificUnitData(unitId);
                if (fetchedUnit.ok) {
                    setUnit(fetchedUnit.body.unit);
                } else {
                    setUnit(null);
                    navigate('/dashboard');
                }
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        if (!unit) {
            fetchUnit();
        }
    }, [unit, api, unitId]);

    async function fetchFaults(){
        setLoading(true);
        const faultsResponse = await getSpecificUnitFaults(unitId);
        if (faultsResponse.ok) {
            const faultsArray = Object.values(faultsResponse.body);
            console.log(faultsArray);
            let active = faultsArray.filter(fault => fault.active === true);
            let nonActive = faultsArray.filter(fault => fault.active === false);
            if (!isContractor) {
                active = active.filter(fault => forNonContractor.includes(fault.enum));
                nonActive = nonActive.filter(fault => forNonContractor.includes(fault.enum));
            }
            setActiveFaults(active);
            setNonActiveFaults(nonActive);
            if (active.length > 0) {
                setHasFault(true);
            } else {
                setHasFault(false);
            }
        } else {
            alert(t('couldNotFetchFaults'), 'error');
        }
        setLoading(false);
    }

    // re-fetch alerts every 30 seconds if on screen
    useEffect(() => {
        fetchFaults();
        const interval = setInterval(fetchFaults, 30000);
        return () => clearInterval(interval);
    }, [api]);

    const clearAllFaults = async () => {
        const response = await clearFaults(api, unitId);
        if (response.ok) {
            alert(t('faultsCleared'), 'success');
            return true;
        } else {
            alert(t('faultsNotCleared'), 'error');
            return false;
        }
    };

    if (!unit) {
        return (
            <AppContainer justifyContent="center">
                <AppLoader />
            </AppContainer>
        );
    }

    return (
        <AppContainer>
            <AppHeader title={t('alerts')} />
            <UnitHeader sn={unit.serial_number} unit={CustomNameUtils(unit, isContractor)} status={hasFault ? 'ERROR' : 'OK'}/>

            <Paper elevation={1} style={{ borderRadius: '0.5em', overflow: 'hidden', }}>
                <Tabs
                    value={activeTab}
                    onChange={(event, newValue) => setActiveTab(newValue)}
                    variant="fullWidth"
                    textColor="inherit"
                    TabIndicatorProps={{
                        style: {
                            borderRadius: '0.5em 0.5em 0 0',
                            backgroundColor: '#4EB6B5',
                        }
                    }}
                >
                    <Tab
                        style={{
                            fontWeight: activeTab === 0 ? '700' : '500',
                            color: activeTab === 0 ? '#4EB6B5' : 'black'
                        }}
                        label={t('active')}
                    />
                    <Tab
                        style={{
                            fontWeight: activeTab === 1 ? '700' : '500',
                            color: activeTab === 1 ? '#4EB6B5' : 'black'
                        }}
                        label={t('resolved')}
                    />
                </Tabs>
            </Paper>

            <AlertsTabPanel
                value={activeTab}
                index={0}
                faults={activeFaults}
                isContractor={isContractor}
                onFaultClick={(fault) => {
                    setDrawerOpen(true);
                    setSelectedFault(fault);
                }}
                emptyMessage={t('noActiveFaults')}
                showThumbsUp={true}
                showContactInfo={!isContractor}
                unitType={unitType}
            />

            <AlertsTabPanel
                value={activeTab}
                index={1}
                faults={nonActiveFaults}
                isContractor={isContractor}
                onFaultClick={(fault) => {
                    setDrawerOpen(true);
                    setSelectedFault(fault);
                }}
                emptyMessage={t('noPreviousFaults')}
                unitType={unitType}
            />

            {isContractor && hasFault && (
                <AppButton onClick={toggleClearErrorDialog}>
                    {t('clearAllAlerts')}
                </AppButton>
            )}


            <AppPop
                title={confirmationState === 'confirm' ? t('clearAlertsConfirm') : t('alertsCleared')}
                handleOpen={clearErrorDialogOpen}
                showActions="true"
                handleClose={() => {
                    toggleClearErrorDialog();
                    setConfirmationState('confirm');
                }}
                handleConfirm={async () => {
                    const success = await clearAllFaults();
                    if (success) {
                        setConfirmationState('cleared');
                        fetchFaults();
                    }
                }}
                confirmationState={confirmationState}
            />

            <AlertDrawer anchor="bottom" open={drawerOpen} onClose={handleDrawer}>
                {selectedFault &&
                    <AlertInfo
                        type={selectedFault.type === 0 ? t('warning') : t('lockout')}
                        // fault={selectedFault.name}
                        fault={getFaultName(t, unitType, selectedFault.enum, selectedFault.type)}
                        occuranceDate={new Date(selectedFault.created * 1000).toLocaleDateString()}
                        frequency={selectedFault.frequency}
                    />
                }
            </AlertDrawer>

        </AppContainer>
    );
}

export default AlertsScreen;
