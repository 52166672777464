import React, {useEffect, useState} from 'react';
import styled from "@emotion/styled";
import { useTranslation } from 'react-i18next';

import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Box, Stack, TextField, Typography} from "@mui/material";

import AppContainer from "../../components/shared/AppContainer";
import AppHeader from "../../components/shared/AppHeader";
import UnitHeader from "../../components/unitSpecific/UnitHeader";
import {OpenInFullIcon} from '../../assets/Icons/Icons.js';

import AppLoader from "../../components/shared/AppLoader";
import UnitStatus from "../../components/unitSpecific/status/UnitStatus";
import ModelViewer from "../../components/ModelViewer";
import AppDrawer from "../../components/shared/AppDrawer";

import {useUnits} from "../../contexts/UnitProvider";
import {useAlert} from "../../contexts/AlertsProvider";
import {useUser} from "../../contexts/UserProvider";
import {CustomNameUtils} from "../../utilities/CustomNameUtils";
import {useApi} from "../../contexts/ApiProvider";
import {editUnitName} from "../../services/api/unit/unitApi";

const UnitContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
    margin: '0.1em',
    flex: '1 0 auto',
    overflowY: 'auto',
});

// use custom component
const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-input': {
        padding: '0.25em 1em',
        fontWeight: '700',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#4EB6B5',
            borderRadius: '1em',
            borderWidth: '1px',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#4EB6B5',
        },
    },
});

function UnitViewScreen() {
    const { t } = useTranslation('unitView');

    const alert = useAlert();
    const {user, isContractor} = useUser();
    const api = useApi();
    const {unitId} = useParams();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {unit, unitType, setUnitType, unitData, getSpecificUnitData, unitFaults, getSpecificUnitFaults} = useUnits();

    const activeFaults = unitFaults
        ? unitFaults.filter(fault => fault.resolved === null)
        : [];

    const hasFault = activeFaults.length > 0;

    const [drawerOpen, setDrawerOpen] = useState(false);

    const [editName, setEditName] = useState({
        editing: false,
        unitName: '',
    });

    const source = searchParams.get('source') || 'units';
    const groupId = searchParams.get('groupId');

    const backButtonRoute = source === 'groups' && groupId
        ? `/dashboard/my_units/groups?groupId=${groupId}`
        : '/dashboard/my_units';

    const handleDrawer = () => {setDrawerOpen(!drawerOpen)};

    useEffect(() => {
        const fetchFaults = async () => {
            await getSpecificUnitFaults(unitId);
        }
        fetchFaults();
    }, []);

    // much better ways of doing this in the unit provider hooks file, remove redundancy... useUnits is already set up for this...
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await getSpecificUnitData(unitId);
            if (response.ok) {
                const fetchedUnit = response.body.unit;
                const determinedUnitType = fetchedUnit.unit_model.name;
                setUnitType(determinedUnitType);
            } else if (response.status === 404) {
                navigate(backButtonRoute);
            }
            setLoading(false);
        };
        fetchData();
        const interval = setInterval(() => {
            getSpecificUnitData(unitId, true);
        }, 3000);
        return () => {clearInterval(interval)};
    }, [unitId, getSpecificUnitData, setUnitType]);

    if (loading || unit === undefined) {
        return (
            <AppContainer justifyContent="center">
                <AppLoader />
            </AppContainer>
        );
    } else if (unitData === null || unit === null || unitType === undefined) {
        return (
            <AppContainer>
                <AppHeader backButtonRoute={`/dashboard/my_units`} />
                <Typography mt={1} variant="h6" textAlign="center" color="secondaryText">{t('dataUnavailable')}</Typography>
            </AppContainer>
        );
    }

    // can be moved to unit hook file eventually...
    const changeName = async () => {
        const { editing, unitName } = editName;
        if (!editing) {
            setEditName({
                editing: true,
                unitName: CustomNameUtils(unit, isContractor),
            });
            return;
        }

        const trimmedName = unitName.trim();
        if (!trimmedName) {
            setEditName({
                editing: false,
                unitName: CustomNameUtils(unit, isContractor),
            });
            return;
        }

        const nameData = {
            unit_id: unitId,
            unit_name: trimmedName,
        };

        try {
            const response = await editUnitName(api, nameData);
            if (response.ok) {
                await getSpecificUnitData(unitId);
            } else {
                alert(t('failedUpdateName'), 'error');
            }
        } catch (error) {
            console.error('Error updating unit name:', error);
        }
        setEditName(prevState => ({
            ...prevState,
            editing: false,
        }));
    };


    return (
        <AppContainer fullwidth>
            <UnitContainer>
                <AppHeader title={editName.editing ? (
                    <StyledTextField onChange={(e) => setEditName({ ...editName, unitName: e.target.value })} autoFocus placeholder={CustomNameUtils(unit, isContractor)} />
                ) : (CustomNameUtils(unit, isContractor))} backButtonRoute={backButtonRoute} rightText={editName.editing ? t('save') : t('edit')} onClickRight={changeName} />
                <UnitHeader sn={unit.serial_number} status={hasFault ? "ERROR" : "OK"} />
                <Stack direction="row">
                    <ModelViewer src={`/models/${unitType.toLowerCase()}_model_${isContractor ? 'contractor' : 'customer'}.glb`} />
                    <OpenInFullIcon color="secondary" fontSize="small" onClick={handleDrawer} />
                </Stack>
                <UnitStatus unitType={unitType} unitData={unitData} contractor={isContractor} isMetric={user.metric} />
            </UnitContainer>
            <AppDrawer title={t('yourUnit')} open={drawerOpen} onClose={handleDrawer}>
                    <ModelViewer src={`/models/${unitType.toLowerCase()}_model_${isContractor ? 'contractor' : 'customer'}.glb`} large={true} />
            </AppDrawer>
        </AppContainer>
    );
}

export default UnitViewScreen;

