import React, {useEffect, useState} from 'react';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { Box } from "@mui/material";
import AppCard from "../shared/AppCard";

import {useUser} from "../../contexts/UserProvider";
import {useUnits} from "../../contexts/UnitProvider";

function UnitPlot({unitId, plotRegisterNumber, title}) {
    const {user} = useUser();
    const [dataPoints, setDataPoints] = useState([]);
    const {getSpecificUnitData} = useUnits();
    const [labels, setLabels] = useState([]);
    const isMetric = user?.metric
    const isHydronicFlow = title === 'Hydronic Flow';
    const unitLabel = isHydronicFlow ? 'GPM' : (isMetric ? '°C' : '°F');
    const [currentDataPoint, setCurrentDataPoint] = useState(null);
    const [averages, setAverages] = useState({ fiveMin: 0, fifteenMin: 0, thirtyMin: 0 });

    const convertToMetric = value => {
        if (isHydronicFlow) return value;
        return isMetric ? (value - 32) * 5 / 9 : value;
    };

    const calculateAverage = (data) => {
        if (data.length === 0) return 0;
        const sum = data.reduce((a, b) => a + b, 0);
        return sum / data.length;
    };

    console.log('App plot rendered')

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getSpecificUnitData(unitId);
                const allData = response.body.parameters;
                const newDataPoint = parseFloat(allData[plotRegisterNumber][0]);
                setCurrentDataPoint(convertToMetric(newDataPoint));
                const newLabel = new Date().toLocaleTimeString();
                const newConvertedDataPoint = convertToMetric(newDataPoint);
                setDataPoints(prevData => {
                    const newData = [...prevData, newConvertedDataPoint].slice(-600);
                    setAverages({
                        fiveMin: calculateAverage(newData.slice(-100)),
                        fifteenMin: calculateAverage(newData.slice(-300)),
                        thirtyMin: calculateAverage(newData)
                    });
                    return newData;
                });
                setLabels(prevLabels => [...prevLabels, newLabel].slice(-100));
            } catch (error) {
                console.error('An error occurred on plot fetch:', error);
            }
        };
        const interval = setInterval(fetchData, 3000);
        return () => clearInterval(interval);
    }, [unitId, plotRegisterNumber]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        scales: {
            x: {
                grid: {
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: false,
                },
                border: {
                    display: true,
                    color: function(context) {
                        const chart = context.chart;
                        const ctx = chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, chart.width, 0);
                        gradient.addColorStop(0, '#37589D');
                        gradient.addColorStop(1, '#4EB6B5');
                        return gradient;
                    },
                    width: 2
                },
                ticks: {
                    font: {
                        size: 10,
                        weight: '400'
                    },
                }
            },
            y: {
                grid: {
                    drawBorder: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
                border: {
                    display: true,
                    color: function(context) {
                        const chart = context.chart;
                        const ctx = chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, chart.width, 0);

                        gradient.addColorStop(0, '#37589D');
                        gradient.addColorStop(1, '#4EB6B5');

                        return gradient;
                    },
                    width: 2
                },
                ticks: {
                    callback: function(value) {
                        return parseFloat(value).toFixed(1) + ' ' + unitLabel;
                    },
                    font: {
                        size: 10,
                        weight: '400'
                    },
                },
            }
        },
        plugins: {
            title: {
                text: `(${currentDataPoint ? currentDataPoint.toFixed(1) + ' ' + unitLabel : '...' })`,
                display: true,
                font : {
                    size: 13,
                    weight: '500'
                },
                padding: {
                    bottom: 25
                },
            },
            legend: {
                display: false
            }
        },
    };

    const dataSet = {
        labels: labels,
        datasets: [{
            fill: true,
            lineTension: 0.2,
            backgroundColor: function(context) {
                const chart = context.chart;
                const ctx = chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
                gradient.addColorStop(0, 'rgba(78,182,181,0.4)');
                gradient.addColorStop(1, 'rgb(250,250,250)');
                return gradient;
            },
            borderColor: function(context) {
                const chart = context.chart;
                const ctx = chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, chart.width, 0);

                gradient.addColorStop(0, '#37589D');
                gradient.addColorStop(1, '#4EB6B5');

                return gradient;
            },
            borderWidth: 2,
            data: dataPoints,
        }]
    };

    return (
        <>
            <AppCard>
                <Box style={{ height: '18em'}}>
                    <Line data={dataSet} options={options} />
                </Box>
            </AppCard>
            {/*<AppCard>*/}
            {/*    <Box>Average last 5 min: {averages.fiveMin.toFixed(1)} {unitLabel}</Box>*/}
            {/*    <Box>Average last 15 min: {averages.fifteenMin.toFixed(1)} {unitLabel}</Box>*/}
            {/*    <Box>Average last 30 min: {averages.thirtyMin.toFixed(1)} {unitLabel}</Box>*/}
            {/*</AppCard>*/}
        </>
    );
}

export default UnitPlot;
