import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

import {useNavigate} from "react-router-dom";
import {Box} from "@mui/material";

import AppContainer from "../../components/shared/AppContainer";
import AddUnitOne from "../../components/addUnit/AddUnitOne";
import AddUnitTwo from "../../components/addUnit/AddUnitTwo";
import AddUnitThree from "../../components/addUnit/AddUnitThree";

import {useApi} from "../../contexts/ApiProvider";
import {useAlert} from "../../contexts/AlertsProvider";
import {useUser} from "../../contexts/UserProvider";
import {addUnit, signChecklist} from "../../services/api/unit/unitApi";


// incorporate step context from reg. here?

function AddUnitScreen() {
    const { t } = useTranslation('units');
    const [activeStep, setActiveStep] = useState(0);
    const [unitApplication, setUnitApplication] = useState(null);
    const [unitSN, setUnitSN] = useState(null);
    const [unitLocation, setUnitLocation] = useState('');

    const { user } = useUser();
    const api = useApi();
    const alert = useAlert();
    const navigate = useNavigate();

    const handleChoiceChange = (value) => {
        setUnitApplication(value);
    };

    const handleSerialChange = (value) => {
        setUnitSN(value);
    };

    const handleLocationChange = (value) => {
        setUnitLocation(value);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = async () => {
        if (!unitSN || unitSN.trim() === '') {
            alert(t('addUnit.Screen.alerts.snEmpty'), 'error');
            return;
        }

        const unitData = {
            serial_number: unitSN,
            application: unitApplication,
        };

        if (Object.keys(unitLocation).length) {
            unitData.address = unitLocation;
        }

        try {
            const response = await addUnit(api, unitData);
            if (response && response.ok) {
                if (response.body.first_installation === true) {
                    const unitId = response.body.id;
                    if (user.role === 4) {
                        try {
                            const signResponse = await signChecklist(api, { document_type: 'Installation', unit_id: unitId });
                            if (!signResponse.ok) {
                                alert('Could not sign for Anesi Admin.', 'error');
                            }
                            navigate('/dashboard/my_units');
                        } catch (err) {
                            console.error('Error signing checklist:', err);
                            navigate('/dashboard/my_units');
                        }
                    } else {
                        navigate(`/dashboard/my_units/${unitId}/assistant`);
                    }
                } else {
                    navigate('/dashboard/my_units');
                }
            } else if (response && response.status === 400) {
                handleNext();
            } else if (response && response.status === 403) {
                alert(t('addUnit.Screen.alerts.unitBelongsToYou'), 'info');
            } else {
                alert(t('addUnit.Screen.alerts.unitNotFound'), 'error');
            }
        } catch (error) {
            console.error('Error adding unit:', error);
            alert('Something went wrong', 'error');
        }
    };

    return (
        <AppContainer>
            <Box display="flex">
                {activeStep === 0 && <AddUnitOne onNext={handleNext} onChoiceChange={handleChoiceChange}/>}
                {activeStep === 1 && <AddUnitTwo onBack={handleBack} onSubmit={handleSubmit} onSerialChange={handleSerialChange} />}
                {activeStep === 2 && <AddUnitThree onBack={handleBack} onSubmit={handleSubmit} onLocationChange={handleLocationChange} />}
            </Box>
        </AppContainer>
    );
}

export default AddUnitScreen;

