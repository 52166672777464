import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useTheme, useMediaQuery } from "@mui/material";

import PublicRoute from "../components/navigation/PublicRoute";
import HomeScreen from "../views/public/HomeScreen";
import AppTransition from "../components/shared/AppTransition";
import LogInScreen from "../views/public/LogInScreen";
import RegisterScreen from "../views/public/RegisterScreen";
import PasswordResetScreen from "../views/public/PasswordResetScreen";
import PasswordResettingScreen from "../views/public/PasswordResettingScreen";
import PrivateRoute from "../components/navigation/PrivateRoute";
import DashScreen from "../views/private/DashScreen";
import SettingsScreen from "../views/private/SettingsScreen";
import AccountScreen from "../views/private/AccountScreen";
import UnitsScreen from "../views/private/UnitsScreen";
import AddUnitScreen from "../views/private/AddUnitScreen";
import NavLayout from "../components/navigation/NavLayout";
import UnitViewScreen from "../views/private/UnitViewScreen";
import ControllerScreen from "../views/private/ControllerScreen";
import DocsScreen from "../views/private/DocsScreen";
import AlertsScreen from "../views/private/AlertsScreen";
import AssistantScreen from "../views/private/AssistantScreen";
import PlotsScreen from "../views/private/PlotsScreen";
import AppContractorForm from "../components/registration/ContractorForm";
import GroupsScreen from "../views/private/GroupsScreen";

const AppRoutes = () => {
    const location = useLocation();
    const theme = useTheme();

    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)');

    const withTransition = (element) => {
        if (prefersReducedMotion) {
            return element;
        }
        return isMobileOrTablet ? <AppTransition>{element}</AppTransition> : element;
    };

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route path="/"
                    element={
                        <PublicRoute>
                            {withTransition(<HomeScreen />)}
                        </PublicRoute>
                    }
                />
                <Route path="/login"
                    element={
                        <PublicRoute>
                            {withTransition(<LogInScreen />)}
                        </PublicRoute>
                    }
                />
                <Route path="/register"
                    element={
                        <PublicRoute>
                            {withTransition(<RegisterScreen />)}
                        </PublicRoute>
                    }
                />
                <Route path="/contractor_form"
                    element={
                        <PublicRoute>
                            {withTransition(<AppContractorForm />)}
                        </PublicRoute>
                    }
                />
                <Route path="/password_reset"
                    element={
                        <PublicRoute>
                            {withTransition(<PasswordResetScreen />)}
                        </PublicRoute>
                    }
                />
                <Route path="/reset"
                    element={
                        <PublicRoute>
                            {withTransition(<PasswordResettingScreen />)}
                        </PublicRoute>
                    }
                />

                <Route path="/dashboard/*"
                    element={
                        <PrivateRoute>
                            <Routes>
                                <Route
                                    index
                                    element={
                                        withTransition(<DashScreen />)
                                    }
                                />
                                <Route path="settings"
                                    element={withTransition(<SettingsScreen />)}
                                />
                                <Route path="my_account"
                                    element={withTransition(<AccountScreen />)}
                                />
                                <Route path="my_units/*"
                                       element={
                                           <Routes>
                                               <Route index element={withTransition(<UnitsScreen />)} />
                                               <Route path="groups" element={withTransition(<GroupsScreen />)} />
                                               <Route path="add_unit" element={withTransition(<AddUnitScreen />)} />
                                               <Route path=":unitId/*"
                                                      element={
                                                          <Routes>
                                                              {/* NavLayout-wrapped routes */}
                                                              <Route
                                                                  path="*"
                                                                  element={
                                                                      <NavLayout>
                                                                          <Routes>
                                                                              <Route index element={withTransition(<UnitViewScreen />)} />
                                                                              <Route path="more-data" element={withTransition(<ControllerScreen />)} />
                                                                              <Route path="documents" element={withTransition(<DocsScreen />)} />
                                                                              <Route path="alerts" element={withTransition(<AlertsScreen />)} />
                                                                              <Route path="plot" element={withTransition(<PlotsScreen />)} />
                                                                          </Routes>
                                                                      </NavLayout>
                                                                  }
                                                              />
                                                              {/* Standalone routes */}
                                                              <Route path="assistant" element={withTransition(<AssistantScreen />)} />
                                                          </Routes>
                                                      }
                                               />
                                           </Routes>
                                       }
                                />
                            </Routes>
                        </PrivateRoute>
                    }
                />
                {/* Fallback Route */}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </AnimatePresence>
    );
}

export default AppRoutes;
