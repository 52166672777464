import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, CardActionArea, IconButton, Typography } from '@mui/material';
import { motion, useMotionValue, useTransform } from 'framer-motion';

import {DeleteIcon, InfoIcon} from '../../assets/Icons/Icons.js';
import { CustomNameUtils } from '../../utilities/CustomNameUtils';

function UnitCard({
                      unit,
                      contractor,
                      navigate,
                      source,
                      swipeIcon,
                      onSwipe,
                      showInfo,
                      onInfoClick, groupId

                  }) {
    const { t } = useTranslation('units');

    const [isOpen, setIsOpen] = useState(false);
    const x = useMotionValue(0);
    const threshold = -100;

    const handleDragEnd = (event, info) => {
        if (info.offset.x < -50) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    };

    const handleUnitClick = () => {
        if (source === 'groups' && groupId) {
            navigate(`/dashboard/my_units/${unit.id}?source=groups&groupId=${groupId}`);
        } else {
            navigate(`/dashboard/my_units/${unit.id}?source=${source}`);
        }
    };

    useEffect(() => {
        if (isOpen) {
            x.set(threshold);
        } else {
            x.set(0);
        }
    }, [isOpen, x, threshold]);

    const swipeProgress = useTransform(x, [0, threshold], [0, 1]);
    const pointerEvents = useTransform(swipeProgress, (v) => (v === 1 ? 'auto' : 'none'));

    return (
        <Box style={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
            <Box
                sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    paddingRight: 1,
                    zIndex: 0,
                }}
                style={{ pointerEvents, }}
            >
                <IconButton
                    color="error"
                    onClick={() => {
                        if (onSwipe) {
                            onSwipe(unit.id);
                        }
                        setIsOpen(false);
                    }}
                >
                    {swipeIcon || <DeleteIcon />}
                </IconButton>

                {contractor && showInfo && (
                    <IconButton color="secondary" onClick={onInfoClick}>
                        <InfoIcon />
                    </IconButton>
                )}
            </Box>

            <motion.div
                drag="x"
                dragConstraints={{ left: threshold, right: 0 }}
                dragElastic={0.2}
                style={{
                    x,
                    position: 'relative',
                    backgroundColor: '#ffffff',
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    width: '100%',
                    overflow: 'hidden',
                    touchAction: 'none',
                    zIndex: 1,
                }}
                onDragEnd={handleDragEnd}
            >
                <CardActionArea
                    onClick={handleUnitClick}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        p={1.5}
                        position="relative"
                    >
                        <Typography fontWeight="bold" variant="subtitle2">
                            {CustomNameUtils(unit, contractor)}
                        </Typography>
                        <Typography variant="body2" color="secondaryText">
                            {t('unitCard.serialNumber')}: {unit.serial_number}
                        </Typography>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: -1,
                                backgroundColor: unit.faults?.length > 0 ? 'error.main' : 'success.main',
                                color: 'white',
                                px: 1.5,
                                py: 0.5,
                                borderTopRightRadius: '10px',
                                borderBottomLeftRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {unit.faults?.length > 0 ? t('unitCard.error') : t('unitCard.operational')}
                        </Box>
                    </Box>
                </CardActionArea>
            </motion.div>
        </Box>
    );
}

export default UnitCard;
