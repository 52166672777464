import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Box} from "@mui/material";

const AppDialog = ({ open, title, children, handleClose, handleConfirm, confirmText , cancelText, icon  }) => {

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: "10px" } }}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    {icon && <Box mr={2}>{icon}</Box>}
                    {title}
                </Box>
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions sx={{ mt: '0.5em', p: 0, display: 'flex', width: '100%' }}>
                {cancelText ? (
                    <Button
                        variant="text"
                        onClick={handleClose}
                        sx={{
                            flex: 1,
                            textAlign: 'center',
                            color: 'gray',
                        }}
                    >
                        {cancelText}
                    </Button>
                ) : null}
                {confirmText ? (
                    <Button
                        variant="contained"
                        onClick={handleConfirm}
                        sx={{
                            flex: cancelText ? 1 : 2,
                            backgroundColor: '#4EB6B5',
                            color: 'white',
                            fontWeight: '600',
                            textAlign: 'center',
                            borderRadius: '0px'
                        }}
                    >
                        {confirmText}
                    </Button>
                ): null}
            </DialogActions>
        </Dialog>
    );
};

export default AppDialog;
