import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import commonEN from './locales/en/common.json';
import publicEN from './locales/en/public/public.json';
import accountEN from './locales/en/profile/account.json';
import alertsEN from './locales/en/unit/alerts/alerts.json';
import controllerEN from './locales/en/unit/controller.json';
import dashboardEN from './locales/en/profile/dashboard.json';
import documentsEN from './locales/en/unit/documents.json';
import settingsEN from './locales/en/profile/settings.json';
import unitsEN from './locales/en/unit/units.json';
import unitViewEN from './locales/en/unit/unitView.json';
import groupsEN from './locales/en/groups/groups.json';
import registerEN from './locales/en/public/register.json';
import faultsEN from './locales/en/unit/alerts/faults.json';
import stateStatusEN from './locales/en/unit/unitS&S.json';
import plotEN from './locales/en/unit/plot.json';

import commonFR from './locales/fr/common.json';
import publicFR from './locales/fr/public/public.json';
import accountFR from './locales/fr/profile/account.json';
import alertsFR from './locales/fr/unit/alerts/alerts.json';
import controllerFR from './locales/fr/unit/controller.json';
import dashboardFR from './locales/fr/profile/dashboard.json';
import documentsFR from './locales/fr/unit/documents.json';
import settingsFR from './locales/fr/profile/settings.json';
import unitsFR from './locales/fr/unit/units.json';
import unitViewFR from './locales/fr/unit/unitView.json';
import groupsFR from './locales/fr/groups/groups.json';
import registerFR from './locales/fr/public/register.json';
import faultsFR from './locales/fr/unit/alerts/faults.json';
import stateStatusFR from './locales/fr/unit/unitS&S.json';
import plotFR from './locales/fr/unit/plot.json';

const resources = {
    en: {
        common: commonEN,
        public: publicEN,
        account: accountEN,
        alerts: alertsEN,
        dashboard: dashboardEN,
        documents: documentsEN,
        settings: settingsEN,
        units: unitsEN,
        unitView: unitViewEN,
        controller: controllerEN,
        groups: groupsEN,
        register: registerEN,
        faults: faultsEN,
        stateStatus: stateStatusEN,
        plot: plotEN,

    },
    fr: {
        common: commonFR,
        public: publicFR,
        account: accountFR,
        alerts: alertsFR,
        dashboard: dashboardFR,
        documents: documentsFR,
        settings: settingsFR,
        units: unitsFR,
        unitView: unitViewFR,
        controller: controllerFR,
        groups: groupsFR,
        register: registerFR,
        faults: faultsFR,
        stateStatus: stateStatusFR,
        plot: plotFR,
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        ns: [
            'common',
            'public',
            'account',
            'alerts',
            'dashboard',
            'documents',
            'settings',
            'units',
            'unitView',
            'controller',
            'groups',
            'register',
            'faults',
            'stateStatus',
            'plot',
        ],
        defaultNS: 'common',
        // detection: {
        //     order: ['querystring', 'cookie'], // Exclude 'localStorage'
        //     caches: ['cookie'], // Exclude 'localStorage'
        // },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
