import React from 'react';
import {Box, Stack} from "@mui/material";

import BottomNav from './BottomNav';

function NavLayout({children}) {
    return (
        <Stack>
            <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                {children}
            </Box>
            <Box sx={{ position: 'sticky', bottom: 0, left: 0, right: 0, zIndex: 999 }}>
                <BottomNav />
            </Box>
        </Stack>
    );
}

export default NavLayout;
