// Update custom unit name
export const editUnitName = async (apiClient, nameData) => {
    try {
        return await apiClient.put('/me/units', nameData);
    } catch (error) {
        console.error('Could not change unit name:', error);
    }
};

export const addUnit = async (apiClient, unitData) => {
    try {
        return await apiClient.post('/me/units', unitData);
    } catch (error) {
        console.error('Could not add unit:', error);
    }
};

export const signChecklist = async (apiClient, installData) => {
    try {
        return await apiClient.post('/signature', installData);
    } catch (error) {
        console.error('Could not sign install:', error);
    }
};
