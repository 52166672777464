import React, {useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import AppContainer from "../../components/shared/AppContainer";
import AppHeader from "../../components/shared/AppHeader";
import {CircularProgress, Stack, Typography} from "@mui/material";
import AppTextInput from "../../components/shared/AppTextInput";
import AppButton from "../../components/shared/AppButton";
import {useApi} from "../../contexts/ApiProvider";
import {useAlert} from "../../contexts/AlertsProvider";

const PasswordResetSchema = (t) => Yup.object({email: Yup.string().email(t('invalidEmail')).required(t('emailRequired'))});

function PasswordResetScreen() {
    const { t } = useTranslation('public');

    const api = useApi();
    const alert = useAlert();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
        setLoading(true);
        const response = await api.post('/tokens/reset', values);
        if (response.ok) {
            alert(t('youWillReceiveEmail'), 'success');
        } else if (response.status === 404) {
            alert(t('emailAddressNotFound'), 'error');
        } else {
            alert(t('errorWithRequest'), 'error');
        }
        setLoading(false);
    }

    return (
        <AppContainer>
            <AppHeader title="Password Reset" backButtonRoute="/login"/>
            <Typography variant="subtitle2" color="secondaryText" mt={1.5} textAlign="center">
                {t('enterEmailInstructions')}
            </Typography>
            <Formik initialValues={{ email: ''}} onSubmit={handleSubmit} validationSchema={PasswordResetSchema(t)}>
                <Form>
                    <AppTextInput style={{ width: '100%'}} name="email" label="Email" type="email" placeholder={t('emailPlaceholder')}/>
                    <div style={{ position: 'relative' }}>
                        <AppButton disabled={loading} variant="primary" type="submit" style={{ marginTop: '1em' }}>{t('request')}</AppButton>
                        {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '60%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
                    </div>
                </Form>
            </Formik>
        </AppContainer>
    );
}

export default PasswordResetScreen;

