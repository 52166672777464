import React from 'react';
import styled from "@emotion/styled";
import { useTranslation } from 'react-i18next';

import {Box, Stack, Typography} from "@mui/material";
import {CheckCircle, Error} from '../../assets/Icons/Icons.js';

const Container = styled(Box)(({ borderColor }) => ({
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${borderColor}`,
    borderRadius: '15px',
    padding: '0.5em',
    margin: '0.5em 0',
    justifyContent: 'space-evenly',
    backgroundColor: 'white',
}));

function UnitHeader({ unit, sn, status }) {
    const { t } = useTranslation('units');

    const StatusIcon = status === 'OK' ? CheckCircle : Error;
    const iconColor = status === 'OK' ? '#4EB6B5' : '#B1494A';
    const borderColor = status === 'OK' ? '#4EB6B5' : '#B1494A';

    return (
        <Container borderColor={borderColor}>
            {unit && (
                <Typography variant="body2">
                    {t('unit')}: {unit}
                </Typography>
            )}
            <Typography variant="body2">
                {t('unitCard.serialNumber')}: {sn}
            </Typography>
            {status && (
                <Stack direction="row" spacing={0.5}>
                    <Typography variant="body2" >
                        {t('system')}:
                    </Typography>
                    <StatusIcon fontSize="small" style={{ color: iconColor }} />
                </Stack>
            )}
        </Container>
    );
}

export default UnitHeader;
