import React from 'react';
import {Card, CardContent, Typography} from "@mui/material";
import styled from '@emotion/styled';

// TODO: Redo this component...

const variantStyles = {
    bad: {
        borderColor: '#B1494A',
    },
    good: {
        borderColor: '#4EB6B5',
    },
    default: {
        borderColor: '#37589D',
    },
    disabled: {
        borderColor: 'lightgrey',
        backgroundColor: 'ghostwhite',
    },
};

const StyledCard = styled(Card)(({ theme, type = "default", scrollable }) => ({
    ...variantStyles[type],
    borderWidth: 1,
    borderStyle: 'solid',
    padding: 0,
    borderRadius: 10,
    overflow: 'auto',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    ...(scrollable && {
        maxHeight: '20em',
        overflowY: 'auto'
    }),
}));

function AppCard({children, onClick, type = "default", className, scrollable}) {
    return (
        <StyledCard className={className} type={type} scrollable={scrollable}>
            <CardContent sx={{ padding: 'auto'}} onClick={onClick}>
                <Typography component="div">
                    {children}
                </Typography>
            </CardContent>
        </StyledCard>
    );
}

export default AppCard;
