import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import {Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";

import AppContainer from "../shared/AppContainer";
import AppButton from "../shared/AppButton";
import AppHeader from "../shared/AppHeader";

const StyledFormControlLabel = styled(FormControlLabel)({
    '& .MuiTypography-root': {
        fontWeight: '700',
        color: 'grey',
    },
});

const CustomRadio = styled(Radio)({
    color: '#37589D',
    '&.Mui-checked': {
        color: '#4EB6B5',
    },
});

// const applicationMapping = {
//     'Forced-Air (w/Anesi AHU)': 0,
//     'Hydronic (radiant floor)': 1,
//     'Commercial water heating': 2,
//     'Pool heating': 3,
//     'Ice melt': 4,
// };

function AddUnitOne(props) {
    const { t } = useTranslation('units');

    const applicationMapping = {
        [t('addUnit.One.applications.forcedAir')]: 0,
        [t('addUnit.One.applications.hydronic')]: 1,
        [t('addUnit.One.applications.commercialWaterHeating')]: 2,
        [t('addUnit.One.applications.poolHeating')]: 3,
        [t('addUnit.One.applications.iceMelt')]: 4,
    };

    const [selected, setSelected] = useState('');
    const handleChange = (ev) => {
        setSelected(ev.target.value);
        props.onChoiceChange(applicationMapping[ev.target.value]);
    };

    return (
        <AppContainer style = {{ width: '100%' }}>
            <AppHeader title={t('addUnit.Screen.title')} backButtonRoute={`/dashboard/my_units`} />
            <Typography  textAlign="center" variant="h6" mt={1} color="secondaryText.main" >
                {t('addUnit.One.selectApplication')}
            </Typography>
            <Box m={1}>
                <FormControl>
                    <RadioGroup onChange={handleChange}>
                        <StyledFormControlLabel
                            value={t('addUnit.One.applications.forcedAir')}
                            control={<CustomRadio />}
                            label={t('addUnit.One.applications.forcedAir')}
                        />
                        <StyledFormControlLabel
                            value={t('addUnit.One.applications.commercialWaterHeating')}
                            control={<CustomRadio />}
                            label={t('addUnit.One.applications.commercialWaterHeating')}
                        />
                        <StyledFormControlLabel
                            disabled={true}
                            value={t('addUnit.One.applications.hydronic')}
                            control={<CustomRadio />}
                            label={t('addUnit.One.applications.hydronic')}
                        />
                        <StyledFormControlLabel
                            disabled={true}
                            value={t('addUnit.One.applications.poolHeating')}
                            control={<CustomRadio />}
                            label={t('addUnit.One.applications.poolHeating')}
                        />
                        <StyledFormControlLabel
                            disabled={true}
                            value={t('addUnit.One.applications.iceMelt')}
                            control={<CustomRadio />}
                            label={t('addUnit.One.applications.iceMelt')}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
            <AppButton onClick={props.onNext} disabled={!selected}>
                {t('addUnit.Screen.continue')}
            </AppButton>
        </AppContainer>
    );
}

export default AddUnitOne;
