import React from 'react';
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import styled from "@emotion/styled";
import {Typography} from "@mui/material";

import {useUnits} from "../../contexts/UnitProvider";
import {useUser} from "../../contexts/UserProvider";
import AppContainer from "../../components/shared/AppContainer";
import AppHeader from "../../components/shared/AppHeader";
import UnitHeader from "../../components/unitSpecific/UnitHeader";
import UnitPlot from "../../components/unitSpecific/UnitPlot";
import AppCard from "../../components/shared/AppCard";

const StyledCard = styled(AppCard)({
    '& .MuiCardContent-root:last-child': {
        padding: 5,
    },
    backgroundColor: '#4EB6B5',
    borderColor: 'transparent',
    color: '#FFFFFF',
    marginTop: '1em'
});

const titleToRegisterMap = {
    "Hydronic Supply": 12,
    "Hydronic Return": 16,
    "Ambient": 10,
    "Hydronic Flow": 17,
    "Supply Air": 42,
    "Mid Tank": 20
};

function PlotsScreen() {
    const { t } = useTranslation('plot');
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const {unitId} = useParams();
    const { isContractor } = useUser();
    const {unit, unitFaults} = useUnits();

    const plotTitle = params.get('title') || "Unit Plot";
    const plotRegister = titleToRegisterMap[plotTitle]

    const [searchParams] = useSearchParams();
    const source = searchParams.get('source') || 'units';
    const groupId = searchParams.get('groupId');

    const backButtonRoute = source === 'groups' && groupId
        ? `/dashboard/my_units/${unitId}?source=groups&groupId=${groupId}`
        : `/dashboard/my_units/${unitId}?source=${source}`;

    // TODO: Better way of doing this below. React has built in hooks for data manipulation...
    if (!unit) {
        return (
            <AppContainer>
                <AppHeader backButtonRoute={backButtonRoute}/>
                <Typography variant="h6">{t('plotErr')}</Typography>
            </AppContainer>
        );
    }

    return (
        <AppContainer>
            <AppHeader backButtonRoute={backButtonRoute} title={t('plot')}/>
            <UnitHeader unit={isContractor ? unit.name_contractor : unit.name_customer} sn={unit.serial_number}  status={unitFaults ? 'ERROR' : 'OK'}  />
            <UnitPlot
                unitId={unitId}
                plotRegisterNumber={plotRegister}
                title={plotTitle}
            />
            <StyledCard>
                <Typography fontWeight="bold" textAlign="center">
                    {t('fetchingData')}
                </Typography>
            </StyledCard>
        </AppContainer>
    );
}

export default PlotsScreen;
